/**
 * Handling upsell variant sequence flow
 */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import COMPONENT from '@/services/ui-component-names';

Vue.mixin({
  computed: {
    ...mapGetters('quiz', ['getProduct']),
    ...mapGetters('upsell', ['getCartProducts']),
    ...mapGetters('brand', ['getBrand']),
    ...mapGetters('upsell-sequence', [
      'getUpsellCurrentComponentName',
      'getUpsellList',
      'getUpsellListCurrentIndex',
      'getUpsellListLength',
      'getUpsellPhysicalList',
      'getUpsellPhysicalListCurrentIndex',
      'getUpsellPhysicalListLength',
    ]),
    isUpsellSequenceLastItem() {
      return this.getUpsellListCurrentIndex === this.getUpsellListLength - 1;
    },
    isUpsellPhysicalLastItem() {
      return this.getUpsellPhysicalListCurrentIndex === this.getUpsellPhysicalListLength - 1;
    },
    isOnlyOnePhysical() {
      return this.getUpsellPhysicalListCurrentIndex === 0;
    },
    showGoBack() {
      return (this.getUpsellPhysicalListCurrentIndex > 0 || this.isUpsellPhysicalLastItem) && !this.isOnlyOnePhysical;
    },
  },
  mounted() {
    this.currentUpsellVariant();
  },
  methods: {
    ...mapActions('upsell-sequence', [
      'setUpsellCurrentComponentNameAction',
      'setUpsellListAction',
      'setUpsellListCurrentIndexAction',
      'setUpsellPhysicalListAction',
      'setUpsellPhysicalListCurrentIndexAction',
    ]),
    currentUpsellVariant() {
      this.setUpsellListAction({
        productType: this.getProduct?.productType,
        pricingModel: this.getProduct?.pricingModel,
        region: this.$countryCode,
        brand: this.getBrand,
      });
    },
    setCurrentUpsellComponent(componentName = this.getUpsellList[this.getUpsellListCurrentIndex]) {
      // Handle digital products
      if (typeof componentName === 'string') {
        this.setUpsellCurrentComponentNameAction(componentName);
        this.setComponentName(componentName);
      }

      // Handle physical products
      if (typeof componentName === 'object' && !Array.isArray(componentName)) {
        this.setUpsellPhysicalListAction(componentName.upsellPhysical);
        this.setUpsellCurrentComponentNameAction(componentName.upsellPhysical[this.getUpsellPhysicalListCurrentIndex]);
        this.setComponentName(componentName.upsellPhysical[this.getUpsellPhysicalListCurrentIndex]);
      }
    },
    goToNextUpsellComponent() {
      // Check if component name is the last item in the array then
      // we send user to order summary page
      if (this.isUpsellSequenceLastItem) {
        // Go to order summary page
        this.setComponentName(COMPONENT.ORDER_SUMMARY);
        return;
      }

      this.setUpsellListCurrentIndexAction(this.getUpsellListCurrentIndex + 1);
      this.setCurrentUpsellComponent(this.getUpsellList[this.getUpsellListCurrentIndex]);
    },
    goToNextPhysicalComponent() {
      // Check if component name is last item in physical array then
      // we send user to shipping form page
      if (this.isUpsellPhysicalLastItem) {
        // Go to shipping form after that we should send user to the next upsell
        this.setComponentName(COMPONENT.UPSELL_SHIPPING);
        return;
      }

      this.setUpsellPhysicalListCurrentIndexAction(this.getUpsellPhysicalListCurrentIndex + 1);
      this.setUpsellCurrentComponentNameAction(this.getUpsellPhysicalList[this.getUpsellPhysicalListCurrentIndex]);
      this.setComponentName(this.getUpsellPhysicalList[this.getUpsellPhysicalListCurrentIndex]);
    },
    goBackUpsellHandler() {
      this.setUpsellPhysicalListCurrentIndexAction(this.getUpsellPhysicalListCurrentIndex);
      this.setUpsellCurrentComponentNameAction(this.getUpsellPhysicalList[this.getUpsellPhysicalListCurrentIndex]);
      this.setComponentName(this.getUpsellPhysicalList[this.getUpsellPhysicalListCurrentIndex]);
    },
    noThanksButtonHandler() {
      if (this.getCartProducts.length && this.isUpsellPhysicalLastItem) {
        this.setComponentName(COMPONENT.UPSELL_SHIPPING);
      } else if (this.getCartProducts.length === 0 && this.isUpsellPhysicalLastItem) {
        this.setUpsellListCurrentIndexAction(this.getUpsellListCurrentIndex + 1);
        this.setUpsellCurrentComponentNameAction(this.getUpsellList[this.getUpsellListCurrentIndex]);
        this.setComponentName(this.getUpsellList[this.getUpsellListCurrentIndex]);
        this.setUpsellPhysicalListCurrentIndexAction(0);
      } else {
        this.goToNextPhysicalComponent();
      }
    },
  },
});
