import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';

export default {
  name: EXPERIMENT_NAMES.YOGA_28_DAY_CHALLENGE_REVAMP,
  includedRoutes: ['/quiz/enter-email-new/', '/quiz/enter-email-new', '/quiz/plan-is-ready', '/quiz/plan-is-ready/'],
  allocateOnClient: true,
  variants: [
    {
      pricing: 1,
      expVariant: '5559-1',
    },
  ],
};
