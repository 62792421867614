
import svgContainer from '@/components/core/svg-container/svg-container.vue';

export default {
  name: 'LayoutLandingFitOver40',

  components: {
    svgContainer,
  },

  mounted() {
    this.queryHandler();
  },
};
