import { REGIONS } from '@/modules/region/domain/region';

import { createUpsell } from '../upsell';

export const YrhVjZ = createUpsell({
  id: 'YrhVjZ',
  desc: `Upsell sequence with Women Collagen upsell`,
  regions: [REGIONS.us],
  seq: [
    'upsell-12-month',
    {
      upsellPhysical: ['upsell-wc'],
    },
    'upsell-fast-food',
    'upsell-detox',
    'upsell-alcohol',
    'upsell-sweet-treats',
  ],
});
