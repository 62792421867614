export const BaseAlert = () => import('../../components/ui/BaseAlert/BaseAlert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/ui/BaseButton/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/ui/BaseCheckbox/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/ui/BaseInput/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseStack = () => import('../../components/ui/BaseStack/BaseStack.js' /* webpackChunkName: "components/base-stack" */).then(c => wrapFunctional(c.default || c))
export const RhLogo = () => import('../../components/ui/RhLogo/RhLogo.vue' /* webpackChunkName: "components/rh-logo" */).then(c => wrapFunctional(c.default || c))
export const RhOtp = () => import('../../components/ui/RhOtp/RhOtp.vue' /* webpackChunkName: "components/rh-otp" */).then(c => wrapFunctional(c.default || c))
export const WebLogo = () => import('../../components/ui/WebLogo/WebLogo.vue' /* webpackChunkName: "components/web-logo" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
