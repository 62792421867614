
import { mapActions, mapGetters } from 'vuex';

import QuizProgress from '@/components/core/quiz-progress/quiz-progress';
import SvgContainer from '@/components/core/svg-container/svg-container';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';
import { isStepDirectionForward } from '@/services/quiz';

export default {
  name: 'LayoutQuiz',
  components: {
    SvgContainer,
    QuizProgress,
  },

  middleware: ['persist-quiz-state'],

  data() {
    return {
      selectedAttribute: 'imperial',
    };
  },
  head() {
    const headConfig = {
      bodyAttrs: {
        class: this.$exp.$classes.join(' '),
      },
      htmlAttrs: {
        lang: this.$i18n.locale.split('-')[0] || 'en',
      },
      style: [
        {
          cssText: this.dynamicStyles,
        },
      ],
    };

    return headConfig;
  },
  computed: {
    ...mapGetters('quiz', [
      'getQuizCurrentIndex',
      'getQuizList',
      'getQuizStepFrom',
      'getQuizStepTo',
      'getQuizVariant',
      'isFitOver40Tour',
    ]),

    pilatesStyles() {
      return {
        ':root': {
          '--c-primary': '#007BBD !important',
          '--c-primary-dark': '#007BBD !important',
          '--c-primary-light': '#3B99CC !important',
          '--border-radius': '6.25rem !important',
          '--c-disabled': '#A4CDE2 !important',
          '--c-gray-200': '#E9F0F4 !important',
        },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.content__heading': { 'text-transform': 'initial !important' },
      };
    },
    traditionalStyles() {
      return {
        ':root': {
          '--c-primary': '#B82F60 !important',
          '--c-primary-dark': '#B82F60 !important',
          '--border-radius': '6.25rem !important',
        },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.content__heading': { 'text-transform': 'initial !important' },
        '.quiz-measurement': { '--c-tab-active': '#299DE0 !important' },
        '.congratulations .quiz-achievable-weight__value': { color: '#B82F60 !important' },
      };
    },
    ketoStyles() {
      return {
        ':root': {
          '--c-primary': '#3B7841 !important',
          '--c-primary-dark': '#3B7841 !important',
          '--c-primary-light': '#4D8A53 !important',
          '--c-disabled': '#ADC6AF !important',
          '--border-radius': '6.25rem !important',
        },
        '.button--primary': { 'box-shadow': 'none !important' },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.content__heading': { 'text-transform': 'initial !important' },
        '.quiz-measurement': { '--c-tab-active': '#418FCA !important' },
        '.congratulations .quiz-achievable-weight__value': { color: '#3B7841 !important' },
      };
    },
    yogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--yoga-primary) !important',
          '--c-primary-dark': 'var(--yoga-primary-dark) !important',
          '--c-primary-light': 'var(--yoga-primary-light) !important',
          '--border-radius': '6.25rem !important',
          '--c-disabled': 'var(--yoga-primary-light) !important',
        },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.main-header': { '--c-background-color': 'var(--yoga-primary) !important' },
        '.quiz-measurement': { '--c-tab-active': '#5695C6 !important' },
        '.button': { 'box-shadow': 'none !important' },
      };
    },
    somaticYogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--somatic-yoga-primary) !important',
          '--c-primary-dark': 'var(--somatic-yoga-primary-dark) !important',
          '--c-primary-light': 'var(--somatic-yoga-primary-light) !important',
          '--border-radius': '6.25rem !important',
          '--c-disabled': 'var(--somatic-yoga-primary-light) !important',
        },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.main-header': { '--c-background-color': 'var(--somatic-yoga-primary) !important' },
        '.quiz-measurement': { '--c-tab-active': '#5695C6 !important' },
        '.quiz-multi-select': { '--c-checkbox-background': '#FCF8F5 !important' },
        '.quiz-multi-select-visual': { '--c-checkbox-background': '#FCF8F5 !important' },
        '.quiz-default': {
          '--c-checkbox-background': '#FCF8F5 !important',
          '--c-banner-background': '#F6FBFE !important',
        },
        '.quiz-default-with-image .control__label': { '--c-checkbox-background': '#FCF8F5 !important' },
        '.button': { 'box-shadow': 'none !important' },
      };
    },
    fitOver40Styles() {
      return {
        ':root': {
          '--c-primary': 'var(--fit-over-40-primary) !important',
          '--border-radius': '8px !important',
        },
        '.quiz-measurement': {
          '--c-tab-active': 'linear-gradient(180deg, #8578CD 0%, #6358AB 100%) !important',
        },
        '.quiz-measurement__tab-active-new': {
          background: 'linear-gradient(180deg, #8578CD 0%, #6358AB 100%) !important',
        },
        body: { 'background-color': '#F0F1F6 !important' },
        '.ui-layout__main': { 'background-color': '#F0F1F6 !important' },
        '.base-button[disabled]': { 'background-color': 'var(--fit-over-40-button--disabled) !important' },
      };
    },
    fitnessWalkingStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--fitness-walking-primary) !important',
          '--c-primary-dark': 'var(--fitness-walking-primary-dark) !important',
          '--c-primary-light': 'var(--fitness-walking-primary-light) !important',
          '--border-radius': '6.25rem !important',
          '--c-disabled': 'var(--fitness-walking-primary-light) !important',
        },
        '.quiz-measurement': {
          '--c-tab-active': 'var(--fitness-walking-primary) !important',
          '--c-background-color': '#FBF8F0 !important',
        },
        '.quiz-multi-select': { '--c-checkbox-background': '#FBF8F0 !important' },
        '.quiz-multi-select-visual': { '--c-checkbox-background': '#FBF8F0 !important' },
        '.quiz-default': {
          '--c-checkbox-background': '#FBF8F0 !important',
          '--c-banner-background': '#F6FBFE !important',
        },
        '.quiz-default-with-image .control__label': { '--c-checkbox-background': '#FBF8F0 !important' },
        '.balance-text': { 'text-transform': 'initial !important' },
        '.main-header': { '--c-background-color': 'var(--fitness-walking-primary) !important' },
        '.button': { 'box-shadow': 'none !important' },
      };
    },
    quizVariantStyles() {
      return {
        [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: this.traditionalStyles,
        [QUIZ_VARIANTS.BODY_NUTRITION]: this.traditionalStyles,
        [QUIZ_VARIANTS.MACRO_DIET]: this.traditionalStyles,
        [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: this.traditionalStyles,
        [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: this.traditionalStyles,
        [QUIZ_VARIANTS.METABOLIC_RENEWAL]: this.traditionalStyles,
        [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: this.traditionalStyles,
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES]: this.traditionalStyles,
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: this.traditionalStyles,
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: this.traditionalStyles,
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_START]: this.traditionalStyles,
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_CORTISOL_CLEANSE]: this.traditionalStyles,
        [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: this.traditionalStyles,
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: this.pilatesStyles,
        [QUIZ_VARIANTS.WALL_PILATES]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_NOW]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_NEW_LANDER]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_LANDER]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: this.pilatesStyles,
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: this.pilatesStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: this.ketoStyles,
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: this.ketoStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: this.yogaStyles,
        [QUIZ_VARIANTS.SOMATIC_YOGA]: this.somaticYogaStyles,
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: this.somaticYogaStyles,
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: this.traditionalStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: this.yogaStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: this.yogaStyles,
        [QUIZ_VARIANTS.WALL_PILATES]: this.fitOver40Styles,
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_MEDITERRANEAN_DIET]: this.traditionalStyles,
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR]: this.yogaStyles,
        [QUIZ_VARIANTS.WALKING_INDOOR]: this.fitnessWalkingStyles,
        [QUIZ_VARIANTS.CHAIR_YOGA_V1]: this.yogaStyles,
      };
    },
    dynamicStyles() {
      return Object.entries(this.quizVariantStyles[this.getQuizVariant] || {})
        .map(([key, value]) =>
          typeof value === 'object'
            ? `${key} { ${Object.entries(value)
                .map(([prop, val]) => `${prop}: ${val};`)
                .join(' ')} }`
            : `${key}: ${value}`,
        )
        .join(' ');
    },
  },
  watch: {
    $route(newVal, oldVal) {
      this.setQuizStepTo(newVal.params.slug);
      this.setQuizStepFrom(oldVal.params.slug);
    },
  },
  mounted() {
    this.queryHandler();
    window.addEventListener('popstate', this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
  methods: {
    ...mapActions('quiz', ['setQuizCurrentIndexAction', 'setQuizStepFrom', 'setQuizStepTo']),
    handlePopstate() {
      if (isStepDirectionForward(this.getQuizList, this.getQuizStepFrom, this.getQuizStepTo)) {
        this.setQuizCurrentIndexAction(this.getQuizCurrentIndex + 1);
      } else {
        this.$nextTick(() => {
          this.setQuizCurrentIndexAction(this.getQuizCurrentIndex - 1);
        });
      }
    },
  },
};
