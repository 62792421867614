import Vue from 'vue';

import * as actions from './actions';
import * as getters from './getters';

import { STICKY } from '../../services/constants';
import {
  SET_PAYMENT_3D_SECURE_REQUIRED,
  SET_PAYMENT_3D_SECURE_URL,
  SET_PAYMENT_ADDRESS,
  SET_PAYMENT_AFFILIATES,
  SET_PAYMENT_APPLE_PAY_INSTANCE,
  SET_PAYMENT_APPLE_PAY_NONCE,
  SET_PAYMENT_APPLE_WALLET_TOKEN,
  SET_PAYMENT_CC_DETAILS,
  SET_PAYMENT_CHECKOUT_RESPONSE,
  SET_PAYMENT_COMPONENT_NAME,
  SET_PAYMENT_COUPON_CODE,
  SET_PAYMENT_CREATE_USER_PROMISE,
  SET_PAYMENT_CUSTOMER_ID,
  SET_PAYMENT_DETECTED_COUNTRY,
  SET_PAYMENT_EMAIL,
  SET_PAYMENT_FIELDS_DOCUMENT_ID,
  SET_PAYMENT_FIRST_NAME,
  SET_PAYMENT_HAS_ERROR,
  SET_PAYMENT_LAST_NAME,
  SET_PAYMENT_LOCATION_ORIGIN,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_OFFER,
  SET_PAYMENT_ORDER_ID,
  SET_PAYMENT_ORDER_SUMMARY_PAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST,
  SET_PAYMENT_PAYPAL_DETAILS,
  SET_PAYMENT_PHONE,
  SET_PAYMENT_SELECTED,
  SET_PAYMENT_SUBSCRIPTION_ID,
  SET_PAYMENT_TOKEN,
  SET_PAYMENT_TRANSACTION_ID,
  SET_PAYMENT_VARIANT,
} from '../mutation-types';

const mutations = {
  [SET_PAYMENT_TOKEN](state, payload) {
    state.paymentPayload.payment.token = payload;
  },
  [SET_PAYMENT_SELECTED](state, payload) {
    state.selectedPayment = payload;
  },
  [SET_PAYMENT_FIRST_NAME](state, payload) {
    state.paymentPayload.firstName = payload;
  },
  [SET_PAYMENT_LAST_NAME](state, payload) {
    state.paymentPayload.lastName = payload;
  },
  [SET_PAYMENT_EMAIL](state, payload) {
    state.paymentPayload.email = payload;
  },
  [SET_PAYMENT_PHONE](state, payload) {
    state.paymentPayload.phoneNumber = payload;
  },
  [SET_PAYMENT_OFFER](state, payload) {
    state.paymentPayload.payment.type = payload;
  },
  [SET_PAYMENT_CHECKOUT_RESPONSE](state, payload) {
    state.checkoutResponse = payload;
  },
  [SET_PAYMENT_METHOD](state, payload) {
    state.paymentPayload.payment.method = payload;
  },
  [SET_PAYMENT_VARIANT](state, payload) {
    state.paymentPayload.payment.variant = payload;
  },
  [SET_PAYMENT_TRANSACTION_ID](state, payload) {
    state.paymentPayload.payment.transactionId = payload;
  },
  [SET_PAYMENT_ORDER_ID](state, payload) {
    state.paymentPayload.payment.orderId = payload;
  },
  [SET_PAYMENT_CUSTOMER_ID](state, payload) {
    state.paymentPayload.payment.customerId = payload;
  },
  [SET_PAYMENT_SUBSCRIPTION_ID](state, payload) {
    state.paymentPayload.payment.subscriptionId = payload;
  },
  [SET_PAYMENT_PAYPAL_DETAILS](state, payload) {
    state.paymentPayload.paypalDetails = payload;
  },
  [SET_PAYMENT_ADDRESS](state, payload) {
    state.paymentPayload.address = payload;
  },
  [SET_PAYMENT_COMPONENT_NAME](state, payload) {
    state.componentName = payload;
  },
  [SET_PAYMENT_HAS_ERROR](state, payload) {
    state.paymentHasError = payload;
  },
  [SET_PAYMENT_CREATE_USER_PROMISE](state, payload) {
    state.createUserPromise = payload;
  },
  [SET_PAYMENT_DETECTED_COUNTRY](state, payload) {
    state.paymentPayload.detectedCountry = payload;
  },
  [SET_PAYMENT_LOCATION_ORIGIN](state, payload) {
    state.paymentPayload.locationOrigin = payload;
  },
  [SET_PAYMENT_FIELDS_DOCUMENT_ID](state, payload) {
    state.paymentPayload.fieldsDocumentId = payload;
  },
  [SET_PAYMENT_CC_DETAILS](state, payload) {
    state.ccDetails = payload;
  },
  [SET_PAYMENT_3D_SECURE_REQUIRED](state, payload) {
    state.is3DSecureRequired = payload;
  },
  [SET_PAYMENT_3D_SECURE_URL](state, payload) {
    state.threeDSecureUrl = payload;
  },
  [SET_PAYMENT_ORDER_SUMMARY_PAID](state, payload) {
    const index = state.orderSummary.paid.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      Vue.set(state.orderSummary.paid, index, payload);
    } else {
      state.orderSummary.paid.push(payload);
    }
  },
  [SET_PAYMENT_ORDER_SUMMARY_UNPAID](state, payload) {
    const index = state.orderSummary.unpaid.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      Vue.set(state.orderSummary.unpaid, index, payload);
    } else {
      state.orderSummary.unpaid.push(payload);
    }
  },
  [SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST](state, payload) {
    state.orderSummary.unpaid = payload;
  },
  [SET_PAYMENT_AFFILIATES](state, payload) {
    state.affiliates = payload;
  },
  [SET_PAYMENT_COUPON_CODE](state, payload) {
    state.couponCode = payload;
  },
  [SET_PAYMENT_APPLE_PAY_INSTANCE](state, payload) {
    state.applePayInstance = payload;
  },
  [SET_PAYMENT_APPLE_PAY_NONCE](state, payload) {
    state.applePayNonce = payload;
  },
  [SET_PAYMENT_APPLE_WALLET_TOKEN](state, payload) {
    state.applePayWalletToken = payload;
  },
};

const state = () => ({
  paymentHasError: false,
  selectedPayment: null,
  componentName: '',
  paymentPayload: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: 'US',
    detectedCountry: null,
    payment: {
      type: '',
      method: STICKY,
      variant: 0,
      orderId: null,
      customerId: null,
      subscriptionId: null,
      transactionId: null,
    },
    paypalDetails: {},
    locationOrigin: null,
  },
  checkoutResponse: null,
  createUserPromise: null,
  ccDetails: null,
  is3DSecureRequired: false,
  threeDSecureUrl: null,
  orderSummary: {
    paid: [],
    unpaid: [],
  },
  affiliates: false,
  couponCode: null,
  applePayInstance: null,
  applePayNonce: null,
  applePayWalletToken: null,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
