import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const IS_DATADOG_ENABLED = true;
const IS_DATADOG_RUM_ENABLED = true;
const isProd = process.env.NUXT_ENV_APP_ENV === 'production';

const initDatadog = ({ appVersion }) => {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') return;

  const sharedDatadogConfig = {
    clientToken: 'pub591f5ad34509223dac1e73d3cf960a6f',
    site: 'datadoghq.eu',
    service: isProd ? 'web' : 'web-staging',
    env: process.env.NODE_ENV,
    version: appVersion,
  };

  if (IS_DATADOG_RUM_ENABLED && isProd) {
    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: '45f3b46e-7d87-48ac-9c77-4d788e93ff8d',
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/https:\/\/us-central1-reversehealth-(dev|web)\.cloudfunctions\.net/],
      trackViewsManually: true,
      excludedActivityUrls: [
        /connect\.facebook/,
        /facebook\.com/,
        /analytics\.tiktok\.com/,
        /api\.segment\.io/,
        /cdn\.segment\.com/,
        /script\.hotjar\.com/,
        /bat\.bing\.com/,
        /t\.co/,
        /analytics\.twitter\.com/,
        /static\.ads-twitter\.com/,
        /google\.com\/collect/,
        /ct\.pinterest\.com/,
        /tvspx\.com/,
        /dwint1\.com/,
        /everflow\.js/,
        /load\.d\.reverse\.health/,
        /s\.pinimg\.com/,
        /static\.hotjar\.com/,
        /dwin1\.com/,
        /t\.reverse\.health/,
        /d\.reverse\.health/,
      ],
    });
  }

  datadogLogs.init({
    ...sharedDatadogConfig,
    sessionSampleRate: 100,
    forwardErrorsToLogs: false,
  });
};

export default ({ $config }) => {
  if (IS_DATADOG_ENABLED && !process.env.IS_CI && !process.env.CI) {
    initDatadog({
      appVersion: $config.appVersion,
    });
  }
};
