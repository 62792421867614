import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: EXPERIMENT_NAMES.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES,
  includedRoutes: [
    '/tour/yoga-28-day-challenge',
    '/tour/yoga-28-day-challenge/',
    '/quiz/ready-to-commit-yoga',
    '/quiz/ready-to-commit-yoga/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE}`,
      expVariant: null,
    },
    {
      tour: QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
      path: `/tour/${QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1}`,
      expVariant: '5410-1',
    },
    {
      tour: QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
      path: `/tour/${QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2}`,
      expVariant: '5410-2',
    },
  ],
};
