import { REGIONS } from '@/modules/region/domain/region';

export const validateUpsellPayload = (payload) => {
  if (!payload) {
    throw new Error('Payload is required');
  }

  const { firstName, lastName, email, orders, payment, siteCountry, uuid } = payload;

  if (!firstName || !lastName || !email || !orders || !payment || !siteCountry || !uuid) {
    throw new Error('Missing required fields in payload');
  }

  const validRegions = [REGIONS.us, REGIONS.uk, REGIONS.au, REGIONS.join];
  const validPaymentMethods = ['sticky', 'paypal', 'paypal_us', 'apple_pay', 'apple_pay_checkout', 'google_pay'];
  const validPaymentTypes = [
    'sevenDays',
    'oneWeek',
    'fourWeeks',
    'twelveWeeks',
    'oneMonth',
    'twoMonths',
    'threeMonths',
    'fourMonths',
    'sixMonths',
    'twelveMonths',
  ];

  if (!validRegions.includes(siteCountry)) {
    throw new Error(`Invalid region: ${siteCountry}`);
  }

  if (!validPaymentMethods.includes(payment.method)) {
    throw new Error(`Invalid payment method: ${payment.method}`);
  }

  if (!validPaymentTypes.includes(payment.type)) {
    throw new Error(`Invalid payment type: ${payment.type}`);
  }
};
