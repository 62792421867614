import { DEFAULT_PRODUCT_TYPES } from '@/modules/product/domain/product';

export default function ({ isDev, $config }, inject) {
  let productTypes = $config.productTypes;

  if (!productTypes) {
    console[isDev ? 'info' : 'error'](`Using default product types as config is not provided`);
    productTypes = DEFAULT_PRODUCT_TYPES;
  }
  const needsToBeParsed = typeof productTypes === 'string';
  inject('productTypes', needsToBeParsed ? JSON.parse(productTypes) : productTypes);
}
