import Cookies from 'cookie-universal';
import crypto from 'crypto';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { useIntercom } from '@/composables/useIntercom';
import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';
import { getNewCheckoutUrlPath } from '@/modules/checkout/container';
import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';
import { REGIONS } from '@/modules/region/domain/region';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';
import { COOKIES, PAYPAL_TYPES, SUBDOMAIN_BY_BRAND } from '@/services/constants';
import { isEmailBlocked } from '@/services/ignored_email_list_from_GTM';
import { getLangFromLocale } from '@/services/locale';

import { getUtmObject, setUtmObject } from '../services/utm';

const touchMap = new WeakMap();

/**
 * Shared global mixins
 * {keyGenerator} - you give it a string and it will return slugify string
 * {delayTouch} - to delay the error message and show it after 1s from typing
 * {handleNext} - to go to the next question and also handles firebase events
 */
Vue.mixin({
  data() {
    return {
      inputType: 'password',
      iconSymbolName: 'icon-eye-strike',
    };
  },
  computed: {
    ...mapGetters('quiz', [
      'getQuizCurrentIndex',
      'getQuizTotalLength',
      'getQuizAnswerList',
      'getQuizVariant',
      'getQuizList',
      'getAnswerById',
      'getProductType',
      'getQuizReferrerLink',
      'getProductBrand',
      'getProduct',
    ]),
    ...mapGetters('payment', ['getUserEmail', 'getAffiliates']),
    ...mapGetters('detected-country', ['getDetectedCountry', 'isPrefixUkOrEU']),
    ...mapState({
      userSubscription: (state) => state.common.userSubscription,
      leadUuid: (state) => state['have-lead'].uuid,
      newCheckoutIdToRedirect: (state) => state['checkout'].checkoutId,
    }),
    getPaypalType() {
      return this.isPrefixUkOrEU ? PAYPAL_TYPES.global : PAYPAL_TYPES.us;
    },
    getLanguage() {
      const usedLocale = this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale);
      return usedLocale ? usedLocale.acceptedLanguage.substring(0, 2) : this.$i18n.locale;
    },
    // Terms of Service url with language mapping
    tosUrl() {
      return this.getLanguage ? `${this.$getUrl('terms')}-${this.getLanguage}` : this.$getUrl('terms');
    },
    // Privacy Policy url with language mapping
    ppUrl() {
      if ([REGIONS.eu, REGIONS.uk].includes(this.$countryCode)) {
        return `${this.$getUrl('privacy')}-eu`;
      }

      return this.getLanguage ? `${this.$getUrl('privacy')}-${this.getLanguage}` : this.$getUrl('privacy');
    },
    isPilatesTour() {
      return [
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4,
      ].includes(this.getQuizVariant);
    },
    isPilatesLegalTour() {
      return [
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
      ].includes(this.getQuizVariant);
    },
    isBodyNutritionTour() {
      return [
        QUIZ_VARIANTS.BODY_NUTRITION,
        QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE,
        QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE,
      ].includes(this.getQuizVariant);
    },
    isNutritionKetoTour() {
      return [
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
      ].includes(this.getQuizVariant);
    },
    isWeightLossMonthlyTour() {
      return [
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS,
      ].includes(this.getQuizVariant);
    },
    isNutritionWeeklyStartTour() {
      return [
        QUIZ_VARIANTS.NUTRITION_WEEKLY_START,
        QUIZ_VARIANTS.NUTRITION_WEEKLY_CORTISOL_CLEANSE,
        QUIZ_VARIANTS.NUTRITION_WEEKLY_MEDITERRANEAN_DIET,
      ].includes(this.getQuizVariant);
    },
    isNewTours() {
      return (
        this.isPilatesTour ||
        this.isBodyNutritionTour ||
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.isNutritionKetoTour ||
        this.shouldReplicateWeightLossMonthlyTour ||
        this.isYogaTour ||
        this.isSomaticYogaTour ||
        this.isSomaticExercisesTour ||
        this.isRHWalkingTour
      );
    },
    shouldReplicateWeightLossMonthlyTour() {
      return [
        QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        QUIZ_VARIANTS.MACRO_DIET,
        QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        QUIZ_VARIANTS.METABOLIC_RENEWAL,
        QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE,
      ].includes(this.getQuizVariant);
    },
    isYogaTour() {
      return [
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_SENIOR,
        QUIZ_VARIANTS.CHAIR_YOGA_V1,
      ].includes(this.getQuizVariant);
    },
    isChairYogaV1Tour() {
      return this.getQuizVariant === QUIZ_VARIANTS.CHAIR_YOGA_V1;
    },
    isYogaMenopauseTour() {
      return this.getQuizVariant === QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE;
    },
    isSomaticYogaTour() {
      return this.getQuizVariant === QUIZ_VARIANTS.SOMATIC_YOGA;
    },
    isSomaticExercisesTour() {
      return this.getQuizVariant === QUIZ_VARIANTS.SOMATIC_EXERCISES;
    },
    shouldRenderWeekly() {
      return this.$t('landingWeekly.country').includes(this.$countryCode) || this.isNewTours;
    },
    isPilates28DayChallengeDisclaimerExperiment() {
      return QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_DISCLAIMER === this.getQuizVariant;
    },
    shouldShowButtonIcon() {
      const variants = [
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US,
        QUIZ_VARIANTS.BODY_NUTRITION,
        QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        QUIZ_VARIANTS.MACRO_DIET,
        QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        QUIZ_VARIANTS.METABOLIC_RENEWAL,
        QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
        QUIZ_VARIANTS.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
        QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
        QUIZ_VARIANTS.SOMATIC_YOGA,
        QUIZ_VARIANTS.SOMATIC_EXERCISES,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.WALKING_INDOOR,
        QUIZ_VARIANTS.CHAIR_YOGA_V1,
      ];
      return variants.includes(this.getQuizVariant);
    },
    isRHWalkingTour() {
      return this.getQuizVariant === QUIZ_VARIANTS.WALKING_INDOOR;
    },
  },

  methods: {
    ...mapActions('payment', ['setComponentName', 'setAffiliates']),
    ...mapActions('footer', ['changeFooterStatusAction']),
    ...mapActions('common', ['setUTMObjectStore']),
    ...mapActions('quiz', ['setQuizCurrentIndexAction', 'setHeaderStatusAction', 'validateStepIdAction']),
    ...mapActions('have-lead', ['checkIfUserHaveLeadDetails', 'setLeadUUID']),
    ...mapActions('logger', ['logEventWithQuizMeta']),
    keyGenerator(value) {
      return value && this.$slugify(value?.toLowerCase());
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    scrollToTop() {
      setTimeout(() => {
        window.scroll({
          top: 1,
          left: 0,
          behavior: 'smooth',
        });
      }, 0);
    },
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 0);
    },
    handleNext(stepId = '') {
      this.scrollToTop();

      this.stepCompletedEvent(this.getQuizCurrentIndex + 1);
      const stepIndex = this.getQuizList.findIndex((quiz) => quiz.id === stepId);
      const eventIndex = stepIndex === -1 ? this.getQuizCurrentIndex + 1 : stepIndex;
      const validatedIndex = Math.min(eventIndex, this.getQuizList.length - 1);

      this.$nextTick(() => {
        this.setQuizCurrentIndexAction(validatedIndex);
      });
    },
    stepCompletedEvent(quizCurrentIndex) {
      const quizId = this.quiz?.id || this.$attrs?.id;
      const analyticsFirestoreId = this.quiz?.analyticsFirestoreId || quizId;

      const isValidStepId = this.validateStepIdAction({
        stepId: analyticsFirestoreId,
        stepNumber: quizCurrentIndex,
      });

      if (!isValidStepId) {
        return;
      }

      const quizType = this.quiz?.type || this.$attrs?.type;
      const quizAnswer = this.getQuizAnswerList.find((quiz) => quiz.id === analyticsFirestoreId)?.answer;

      const gtmObject = {
        event: 'step_completed',
        step_id: analyticsFirestoreId,
        tour_step_number: quizCurrentIndex,
      };

      if (quizType === 'measurement') {
        gtmObject.measurement_unit = this.getUnits ?? this.$t('units');
      }

      if (quizAnswer) {
        gtmObject.answer = quizAnswer;
      }

      this.$gtm.push(gtmObject);
    },
    paymentDeclineEvent({ method, type, error_message }) {
      this.$gtm.push({
        brand: this.getProductBrand,
        event: 'payment_declined',
        method,
        type,
        error_message,
      });
    },
    toggleInputTypeHandler() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
        this.iconSymbolName = 'icon-eye';
      } else {
        this.inputType = 'password';
        this.iconSymbolName = 'icon-eye-strike';
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getTourFromExperiment() {
      const tourExperimentsVariants = this.$exp.activeExperiments.map((e) => e.variants.find((v) => v.tour !== null));
      if (!tourExperimentsVariants.length) {
        return;
      }
      if (tourExperimentsVariants.length > 1 && !process.server) {
        this.$sentry.captureMessage(new Error('Multiple Tour experiments variants active simultanously', 'error'));
        console.warn('Multiple Tour experiments variants active simultanously', tourExperimentsVariants);
      }
      return tourExperimentsVariants[0].tour;
    },
    isEmailBlocked(email) {
      return isEmailBlocked(email);
    },
    queryHandler() {
      // Loop through url query and store only the ones that startsWith {UTM}
      const { query } = this.$route;
      const validParams = Object.keys(query).filter((key) => key.startsWith('utm'));
      const utmArray = validParams.map((el) => ({
        [el]: query[el] && Array.isArray(query[el]) ? query[el][0] : query[el],
      }));

      const utmObject = Object.assign({}, ...utmArray);

      // Only set it to vuex store if there is value in the array
      !!utmArray.length && (this.setUtmObject(utmObject) || this.setUTMObjectStore(utmObject));

      const { utm_medium: utmMedium } = query;

      if (utmMedium === 'cpc') {
        this.setHeaderStatusAction(false);
      }

      if (query.aff) {
        this.setAffiliates(true);
      }
    },
    getUtmObject,
    setUtmObject,
    measurementsHandler(obj) {
      const currentObj = this.getQuizAnswerList.find((el) => el.id === 'measurements');
      return {
        id: 'measurements',
        selectedAttribute: obj.selectedAttribute || currentObj?.selectedAttribute,
        answer: {
          ...currentObj?.answer,
          ...obj.answer,
        },
      };
    },
    checkTourParamsForGTM(quizVariant, lang) {
      if (!quizVariant) {
        console.warn('Quiz variant not found when event occured');
        this.$sentry.captureMessage(new Error('Quiz variant not found when event occured', 'error'));
      }

      if (!lang) {
        console.warn('Language not found when event occured');
        this.$sentry.captureMessage(new Error('Language not found when event occured', 'error'));
      }
    },
    setupIntercom() {
      if (window.Intercom) {
        const INTERCOM_SECRET_KEY = process.env.NUXT_ENV_INTERCOM_SECRET || '';
        const isManagePageOrChildren = this.$route.path.startsWith('/manage');

        if (['/login', '/contact'].includes(this.$route.path) || isManagePageOrChildren) {
          const intercom = useIntercom({
            intercomAppID: this.$config.intercomAppID,
            intercomSecretKey: INTERCOM_SECRET_KEY,
          });

          const cookies = Cookies();
          const storedUserAttrs = cookies.get(COOKIES.userAttrs);

          const desiredAttrs = isManagePageOrChildren
            ? {
                email: storedUserAttrs?.email || this.userSubscription?.userAttrs?.email,
                userId:
                  storedUserAttrs?.uuid || this.userSubscription?.uuid || this.userSubscription?.userAttrs?.intercomId,
              }
            : { email: undefined, userId: undefined };

          intercom.boot(desiredAttrs);
        }
      }
    },
    showIntercomWidget() {
      const INTERCOM_SECRET_KEY = process.env.NUXT_ENV_INTERCOM_SECRET || '';
      const cookies = Cookies();
      const storedUserAttrs = cookies.get(COOKIES.userAttrs);
      const email = storedUserAttrs?.email;
      const userId = storedUserAttrs?.uuid;

      const intercom = useIntercom({
        intercomAppID: this.$config.intercomAppID,
        intercomSecretKey: INTERCOM_SECRET_KEY,
      });

      if (email && userId) {
        intercom.boot({ email, userId });
      } else {
        intercom.update();
      }
    },
    isLandingPageEventPushed() {
      for (let i = 0; i < window?.dataLayer?.length; i++) {
        const eventData = window?.dataLayer[i];
        if (eventData.event === 'landing_page') {
          return true; // landing_page event found
        }
      }
      return false; // landing_page event not found
    },
    landingPageGtmHandler() {
      this.$nextTick(() => {
        try {
          const usedLocale = this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale);
          const language = usedLocale?.acceptedLanguage.substring(0, 2) || 'en';

          this.checkTourParamsForGTM(this.getQuizVariant, language);

          this.$gtm.push({
            event: 'landing_page',
            brand: this.getProductBrand,
            landing_page_path: this.removeLastSlash(location.pathname),
            product_type: this.getProductType,
            tour_id: this.getQuizVariant,
            language,
            app_version: this.$config.appVersion,
            region: this.$countryCode,
            host: this.$host,
            has_landing_page: true,
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });

          this.$gtm.push({
            event: 'geo_data',
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });

          this.$gtm.push({
            tour_id: this.getQuizVariant,
            app_version: this.$config.appVersion,
            language,
            region: this.$countryCode,
            host: this.$host,
            has_landing_page: this.isLandingPageEventPushed(),
            landing_page_path: this.removeLastSlash(location.pathname),
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });
        } catch (error) {
          this.$sentry.captureMessage(new Error(`Error in landingPageGtmHandler: ${error}`, 'error'));
        }
      });
    },
    tinyHash(data, outputLength = 6) {
      return crypto.createHmac('sha256', 'tinyHash').update(data).digest('hex').substring(0, outputLength);
    },
    removeLastSlash(str) {
      return str.substring(str.length - 1, str.length) === '/' ? str.substring(0, str.length - 1) : str;
    },
    async navigateToOffersPage() {
      try {
        if (!this.leadUuid) {
          this.$sentry.captureMessage('No lead uuid found when redirecting to new checkout', {
            level: 'info',
            extra: { tourId: this.getQuizVariant },
          });
          this.$router.push(this.$getUrl('home'));
          return;
        }

        const checkoutABTest = this.$exp?.activeExperiments.find(({ experiment }) =>
          [
            EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_CHECKOUT_REMOVE_PAYPAL_OPTION_IN_BR,
            EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_CHECKOUT_MONEY_BACK_GUARANTEE,
            EXPERIMENT_NAMES.YOGA_FULL_BODY_BEFORE_AFTER_IMAGES,
            EXPERIMENT_NAMES.PILATES_CHALLENGE_MULTIPLE_PLAN_SELECTOR,
            EXPERIMENT_NAMES.YOGA_28_DAY_CHALLENGE_REVAMP,
          ].includes(experiment?.name),
        );

        const checkoutUrl = this.$config.newCheckoutURL.replace(
          SUBDOMAIN_BY_BRAND[PROJECT_BRANDS.reverse],
          SUBDOMAIN_BY_BRAND[this.getProduct.brand] || SUBDOMAIN_BY_BRAND[PROJECT_BRANDS.reverse],
        );

        getNewCheckoutUrlPath.execute(
          {
            checkoutId: this.newCheckoutIdToRedirect,
            leadUuid: this.leadUuid,
            locale: getLangFromLocale(this.getLanguage),
            uiVariant: checkoutABTest ? checkoutABTest?.variants[0]?.expVariant : null,
            affiliate: this.getAffiliates ? 1 : null,
          },
          {
            onSuccess: (checkoutPath) => {
              location.replace(`${checkoutUrl}/${checkoutPath}`);
            },
            onFailure: () => {
              this.$sentry.captureMessage('Redirect to default checkout');
              this.logEventWithQuizMeta({
                message: 'Redirect to default checkout',
                tag: 'redirect-to-default-checkout-id',
              });

              const CHECKOUT_PATHS = {
                [PROJECT_BRANDS.fitover40]: 'C145s1C',
                default: 'RyMZY1',
              };
              const checkoutId = CHECKOUT_PATHS[this.getProduct.brand] || CHECKOUT_PATHS.default;
              const fallbackCheckoutPath = `${checkoutId}/offers?uuid=${this.leadUuid}&locale=en`;

              location.replace(`${checkoutUrl}/${fallbackCheckoutPath}`);
            },
          },
        );
      } catch (e) {
        this.$sentry.captureException(e);
        this.$router.push(this.$getUrl('home'));
      }
    },
  },
});
