import Cookies from 'cookie-universal';

import { getProductVerticalAndTypeByProduct } from '@/modules/product/container';
import { getLoggerService } from '@/services/logger';

const cookies = Cookies();
const { logger } = getLoggerService();

function sendLog({
  message,
  tag,
  vertical,
  type,
  quizId,
  region,
  uuid,
  experiments,
  source,
  pricingModel,
  meta,
  error,
  brand,
}) {
  logger.info(message, {
    tags: [tag],
    ...(error && { error: getErrorDetails(error) }),
    meta: {
      ...meta,
      region,
      brand,
      lead: {
        uuid,
        quizId,
        experiments,
        source,
      },
      checkout: {
        id: '-',
        vertical,
        type,
        pricingModel,
      },
    },
  });
}

function isHttpError(error) {
  return error.response !== undefined || error.statusCode !== undefined;
}

function getErrorDetails(error) {
  const errorDetails = {
    statusCode: 500,
    message: error.message || 'An unknown error occurred',
    errorType: error.constructor?.name || 'Error',
    stackTrace: error.stack || 'No stack trace available',
  };

  if (isHttpError(error)) {
    if (error.response && error.response.status) {
      errorDetails.statusCode = error.response.status;
    } else if (error.statusCode) {
      errorDetails.statusCode = error.statusCode;
    }
  }

  return errorDetails;
}

export function logEventWithQuizMeta({ rootState }, { message, tag, meta = {}, error }) {
  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const { vertical, type, brand } = getProductVerticalAndTypeByProduct.execute({ product });
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  sendLog({
    message,
    tag,
    vertical,
    type,
    quizId,
    region,
    meta,
    uuid,
    experiments,
    source,
    pricingModel,
    error,
    brand,
  });
}

export function logUniqueEventWithQuizMeta({ rootState }, { message, tag, cookieName, meta = {} }) {
  const cookieValue = cookies.get(cookieName);

  if (cookieValue) return;

  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const { vertical, type, brand } = getProductVerticalAndTypeByProduct.execute({ product });
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  sendLog({ message, tag, vertical, type, quizId, region, uuid, experiments, source, pricingModel, meta, brand });
  cookies.set(cookieName, true);
}

export function logUpsellFlowStartEvent(_, { uuid, priceVariant }) {
  const region = this.$countryCode;

  logger.info('upsell_flow_started', {
    tags: ['upsell_flow_started'],
    meta: {
      uuid,
      priceVariant,
      region,
    },
  });
}
