
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  computed: {
    homeUrl() {
      if (process.client) {
        const hostname = window.location.hostname;
        if (hostname.includes('fitover40.health')) {
          return this.$getUrl('homeFitOver40');
        }
      }
      return this.$getUrl('home');
    },
  },
};
