import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_CHECKOUT_REMOVE_PAYPAL_OPTION_IN_BR,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  allocateOnClient: true,
  variants: [
    {
      expVariant: null,
    },
    {
      expVariant: '5270-1',
    },
  ],
};
