export const upsellRepository = {
  async purchaseUpsell(payload) {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch('/upsell_payment_subscription', options);
      const data = await response.json();

      if (!response.ok) {
        const error = new Error(data.message);
        error.response = data;
        throw error;
      }

      return data;
    } catch (error) {
      error.message = `Error in purchaseUpsell repository: ${error.message}`;
      error.payload = payload;
      throw error;
    }
  },
};
