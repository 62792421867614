import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_BMI_STEP,
  includedRoutes: [
    '/tour/pilates-28-day-challenge',
    '/tour/pilates-28-day-challenge/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4}`,
    },
  ],
};
