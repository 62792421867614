export default async function (context, inject) {
  let jsonData = null;
  try {
    const response = await context.$axios.get(context.$config.abTestingKv, { timeout: 5000 });
    jsonData = response.data;

    inject('kvExperiments', Array.isArray(jsonData) ? jsonData : []);
  } catch {
    // fail silently. It's a known error and is poluting the logs.
    inject('kvExperiments', []);
  }
}
