
import { QUIZ_PRODUCT_TYPES } from '@/modules/product/domain/product';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: 'RhLogo',

  props: {
    type: {
      type: String,
      default: 'nutrition-generic',
      validator: (val) => [...Object.values(QUIZ_PRODUCT_TYPES)].includes(val),
    },
  },

  computed: {
    getLogoVariant() {
      const variantMap = {
        [QUIZ_PRODUCT_TYPES.nutritionMenopause]: 'rh-health-logo-new.png',
        [QUIZ_PRODUCT_TYPES.nutritionKeto]: 'rh-keto-logo.png',
        [QUIZ_PRODUCT_TYPES.nutritionGeneric]: 'rh-health-logo-new.png',
        [QUIZ_PRODUCT_TYPES.fitnessPilates]: 'rh-pilates-logo.png',
        [QUIZ_PRODUCT_TYPES.fitnessYoga]: 'rh-yoga-logo.png',
        [QUIZ_PRODUCT_TYPES.fitnessYogaMenopause]: 'rh-yoga-logo.png',
        [QUIZ_PRODUCT_TYPES.fitnessSomaticYoga]: 'rh-somatic-yoga-logo.png',
        [QUIZ_PRODUCT_TYPES.fitnessSomaticExercises]: 'rh-somatic-yoga-logo.png',
        [QUIZ_PRODUCT_TYPES.fitnessWalking]: 'rh-fitness-walking.svg',
      };

      if (this.getQuizVariant === QUIZ_VARIANTS.WALL_PILATES) {
        return 'fit-over40.png';
      }

      return variantMap[this.type] || 'nutrition-generic';
    },
  },
};
