const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticateUser'] = require('../middleware/authenticateUser.js')
middleware['authenticateUser'] = middleware['authenticateUser'].default || middleware['authenticateUser']

middleware['base-tour'] = require('../middleware/base-tour.js')
middleware['base-tour'] = middleware['base-tour'].default || middleware['base-tour']

middleware['check-source'] = require('../middleware/check-source.js')
middleware['check-source'] = middleware['check-source'].default || middleware['check-source']

middleware['csp'] = require('../middleware/csp.js')
middleware['csp'] = middleware['csp'].default || middleware['csp']

middleware['detected-country'] = require('../middleware/detected-country.js')
middleware['detected-country'] = middleware['detected-country'].default || middleware['detected-country']

middleware['domain-brand'] = require('../middleware/domain-brand.js')
middleware['domain-brand'] = middleware['domain-brand'].default || middleware['domain-brand']

middleware['fix-double-slash'] = require('../middleware/fix-double-slash.js')
middleware['fix-double-slash'] = middleware['fix-double-slash'].default || middleware['fix-double-slash']

middleware['is-quiz-ready'] = require('../middleware/is-quiz-ready.js')
middleware['is-quiz-ready'] = middleware['is-quiz-ready'].default || middleware['is-quiz-ready']

middleware['persist-quiz-state'] = require('../middleware/persist-quiz-state.js')
middleware['persist-quiz-state'] = middleware['persist-quiz-state'].default || middleware['persist-quiz-state']

middleware['redirect-region'] = require('../middleware/redirect-region.js')
middleware['redirect-region'] = middleware['redirect-region'].default || middleware['redirect-region']

export default middleware
