/**
 * List of all auth component names
 * @return {String} that contain single component name based on action
 */
export const AUTH_LOGIN = 'auth-login';
export const AUTH_LOGIN_SKIP = 'auth-login-skip';
export const AUTH_MAGIC_LINK_SENT = 'auth-magic-link-sent';
export const AUTH_ENTER_PASSWORD = 'auth-enter-password';
export const AUTH_REQUEST_PASSWORD_RESET = 'auth-request-password-reset';
export const AUTH_RESET_PASSWORD = 'auth-reset-password';
export const AUTH_RESET_PASSWORD_THANK_YOU = 'auth-reset-password-thank-you';
export const AUTH_LOGIN_TO_REVERSE_HEALTH_APP = 'auth-login-to-reverse-health-app';

export const AUTH_LOGIN_SITE = 'auth-login-site';
export const AUTH_LOGIN_MAGIC_LINK_SENT = 'auth-login-magic-link-sent';
export const AUTH_FO40_LOGIN_SITE = 'auth-fo40-login-site';

// just to avoid nuxt error it's looking for export default in all files
export default {};
