/**
 * Get detected country
 * @param {object} state - The vuex state object
 * @return {String} Get detected country
 */
export const getDetectedCountry = (state) => state.detectedCountry;

/**
 * Get current domain prefix value
 * @param {object} state - The vuex state object
 * @return {String} Current domain prefix value
 */
export const getCurrentDomainPrefix = (state) => state.currentDomainPrefix;

/**
 * Checking if current domain prefix is UK || EU
 * @param {object} state - The vuex state object
 * @param {object} getters - The vuex getters object
 * @return {Boolean} Checking if current domain prefix is UK || EU
 */
export const isPrefixUkOrEU = (state, getters) => {
  const prefixes = ['UK', 'EU', 'JOIN'];
  return prefixes.includes(getters.getCurrentDomainPrefix);
};

/**
 * Checking if current domain prefix is UK
 * @param {object} state - The vuex state object
 * @param {object} getters - The vuex getters object
 * @return {Boolean} Checking if current domain prefix is UK
 */
export const isPrefixUK = (state, getters) => {
  const prefixes = ['UK'];
  return prefixes.includes(getters.getCurrentDomainPrefix);
};
