import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';
import { REGIONS } from '@/modules/region/domain/region';

/**
 * List of global constants
 * @return {Object} that contain all global constants
 */
export const PAYPAL_TYPES = Object.freeze({
  global: 'paypal',
  us: 'paypal_us',
});

export const STICKY = 'sticky';

export const APPLE_PAY_BRAINTREE = 'apple_pay';

export const APPLE_PAY_CHECKOUT = 'apple_pay_checkout';

export const MEASUREMENTS_ATTRIBUTES = Object.freeze({
  imperial: 'imperial',
  metric: 'metric',
});

export const COOKIES = Object.freeze({
  token: 'token',
  lang: 'rhLang',
  userAttrs: 'userAttrs',
  uniquePaymentOptionsPageEvent: 'uniquePaymentOptionsPageEvent',
  uniqueLeadCreatedEvent: 'uniqueLeadCreatedEvent',
  uniqueConfirmationPageEvent: 'uniqueConfirmationPageEvent',
});

export const PRICING_MODELS = Object.freeze({
  monthly: 'Monthly',
  weekly: 'Weekly',
});

export const STATES_LIST = Object.freeze([
  { label: 'Alabama', value: 'alabama' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'Arizona', value: 'arizona' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'Florida', value: 'florida' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Hawaii', value: 'hawaii' },
  { label: 'Idaho', value: 'idaho' },
  { label: 'Illinois', value: 'illinois' },
  { label: 'Indiana', value: 'indiana' },
  { label: 'Iowa', value: 'iowa' },
  { label: 'Kansas', value: 'kansas' },
  { label: 'Kentucky', value: 'kentucky' },
  { label: 'Louisiana', value: 'louisiana' },
  { label: 'Maine', value: 'maine' },
  { label: 'Maryland', value: 'maryland' },
  { label: 'Massachusetts', value: 'massachusetts' },
  { label: 'Michigan', value: 'michigan' },
  { label: 'Minnesota', value: 'minnesota' },
  { label: 'Mississippi', value: 'mississippi' },
  { label: 'Missouri', value: 'missouri' },
  { label: 'Montana', value: 'montana' },
  { label: 'Nebraska', value: 'nebraska' },
  { label: 'Nevada', value: 'nevada' },
  { label: 'New Hampshire', value: 'new hampshire' },
  { label: 'New Jersey', value: 'new jersey' },
  { label: 'New Mexico', value: 'new mexico' },
  { label: 'New York', value: 'new york' },
  { label: 'North Carolina', value: 'north carolina' },
  { label: 'North Dakota', value: 'north dakota' },
  { label: 'Ohio', value: 'ohio' },
  { label: 'Oklahoma', value: 'oklahoma' },
  { label: 'Oregon', value: 'oregon' },
  { label: 'Pennsylvania', value: 'pennsylvania' },
  { label: 'Rhode Island', value: 'rhode island' },
  { label: 'South Carolina', value: 'south carolina' },
  { label: 'South Dakota', value: 'south dakota' },
  { label: 'Tennessee', value: 'tennessee' },
  { label: 'Texas', value: 'texas' },
  { label: 'Utah', value: 'utah' },
  { label: 'Vermont', value: 'vermont' },
  { label: 'Virginia', value: 'virginia' },
  { label: 'Washington', value: 'washington' },
  { label: 'West Virginia', value: 'west virginia' },
  { label: 'Wisconsin', value: 'wisconsin' },
  { label: 'Wyoming', value: 'wyoming' },
]);

export const RESTRICTED_STATES = Object.freeze(['mississippi']);

export const CURRENCY_MAP = Object.freeze({
  USD: '$',
  GBP: '£',
  EUR: '€',
  AUD: 'AUD',
  CAD: 'CAD',
  NZD: 'NZD',
  ZAR: 'ZAR',
});

export const RH_IMAGES_HOSTS = Object.freeze({
  LOCALHOST: 'http://localhost:3001',
  STAGING: 'https://staging-static.reverse.health',
  PRODUCTION: 'https://static.reverse.health',
});

export const F040_IMAGES_HOSTS = Object.freeze({
  LOCALHOST: 'http://localhost:3001',
  STAGING: 'https://staging-static.fitover40.health',
  PRODUCTION: 'https://static.fitover40.health',
});

export const MAGIC_LINK_VERSION = 2;

export const SUBDOMAIN_BY_BRAND = Object.freeze({
  [PROJECT_BRANDS.reverse]: 'reverse.health',
  [PROJECT_BRANDS.fitover40]: 'fitover40.health',
});

export const REGION_LOCALE_PILATES_28_DAY_CHALLENGE = Object.freeze({
  [REGIONS.us]: ['en'],
  [REGIONS.au]: ['en'],
  [REGIONS.uk]: ['en'],
  [REGIONS.join]: ['en'],
  [REGIONS.eu]: ['en', 'de', 'fr'],
  [REGIONS.latam]: ['es'],
  [REGIONS.br]: ['pt'],
});
