/**
 * Get login user email
 * @param {object} state - The vuex state object
 * @return {Object} User Subscription object (contain the status of subscription)
 */
export const cancellationStatus = (state) => state.userSubscription?.subscription?.status?.toLowerCase() || 'active';

export const activeSubsWithHistory = (state) => {
  const { subscriptionHistory } = state;

  if (Array.isArray(subscriptionHistory)) {
    return subscriptionHistory[subscriptionHistory.length - 1];
  }

  return subscriptionHistory;
};

export const contentByTrafficSource = (state) => state.showContentByTrafficSource;

export const isSourceApp = (state) => state.sourceApp;

export const getUTMObjectStore = (state) => state.utmObject;

export const getIsMembersArea = (state) => state.isMembersArea;
