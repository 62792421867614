/**
 * List of all endpoints URLS
 * @return {Object} that contain all endpoints URLS
 */
export default {
  GENERATE_LEAD: '/v2/leads',
  UPDATE_LEAD: (uuid) => `/v2/leads/${uuid}`,
  REQUEST_PASSWORD_RESET: '/request_password_reset',
  RESET_PASSWORD: '/reset_password',
  PAYMENT_TOKEN: '/tokenize_payment',
  PAYMENT_CHECKOUT: '/checkout',
  PAYMENT_CHECKOUT_PAYMENT: '/checkout_payment',
  PAYMENT_CHECKOUT_PAYMENT_UPSELL_STANDALONE: '/checkout_payment_upsell_standalone',
  PAYMENT_CHECKOUT_USER: '/checkout_user',
  CHECK_USER_EXIST: '/v2/users/check-exists',
  SEND_MAGIC_LINK: '/v2/users/send-magic-link',
  SUGGESTIC_LOGIN: '/login',
  LOGIN: '/tt/users/web-magic-link',
  CHECK_USER_SUBSCRIPTION: '/v2/users/check-subscription',
  UPSELL_PAYMENT: '/upsell_payment',
  UPSELL_PURCHASE: '/upsell_purchase',
  CHANGE_EMAIL: '/change_email',
  CANCEL_SUBSCRIPTION: '/v2/cancel-subscription',
  UPDATE_SUBSCRIPTION_FROM_CANCEL: '/update_subscription_from_cancel',
  PAUSE_SUBSCRIPTION_FROM_CANCEL: '/pause_subscription_from_cancel',
  GET_USER: '/get_user',
  GET_USERS: '/v2/users',
  GET_SUBSCRIPTION_HISTORY: '/v2/subscription',
  REACTIVATE_SUBSCRIPTION: '/reactivate_subscription',
  CLOUDFLARE_QUIC: 'https://cloudflare-quic.com/b/headers',
  CLOUDFLARE_QUIC_ALTERNATIVE: 'https://www.cloudflare.com/cdn-cgi/trace',
  THREE_D_SECURE_LINK: (orderId) =>
    `${process.env.NUXT_ENV_FIREBASE_CLOUD_FUNCTIONS}/threedsecure_redirect?order_id=${orderId}`,
  GET_ORDER_SUBSCRIPTION: '/order_subscription',
  UPDATE_USER_PAYMENT_DATA: '/update_user_payment_data',
  UPDATE_TAPPTITUDE_PASSWORD: '/tt/users/update-password',
  REQUEST_TAPPTITUDE_PASSWORD_RESET: '/tt/users/reset-password',
  SEND_WELCOME_SMS: '/v2/users/phone',
  CHECKOUT_PAYMENT_COUPON_CODE: '/v2/checkout-payment/coupon-code',
  CHECKOUT_PAYMENT_ALTERNATIVE_PROVIDER: '/v2/checkout-payment/get-alternative-provider',
};
