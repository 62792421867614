import { ABTestingNuxt } from '../services/ab-testing';

export default function (context, inject) {
  const abTesting = new ABTestingNuxt(context);
  const variant = parseInt(context.query.variant) ?? undefined;
  const exp = abTesting.createExp();

  context.app.router.afterEach((to, from, failure) => {
    if (!failure) {
      exp.experiments.push(...abTesting.allocate(variant));
      if (!to.fullPath.includes('/payment-options')) {
        abTesting.sendToAnalytics(exp.activeExperiments);
      }
    }
  });

  const updateExperiments = () => {
    exp.experiments = abTesting.filterByTourId(exp.experiments);
    abTesting.sendToAnalytics(exp.activeExperiments);
  };

  inject('updateExp', updateExperiments);
  inject('exp', exp);
}
