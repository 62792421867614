export const getPageNameByUrl = (url) => {
  const urlPath = new URL(url).pathname;
  const trimmedSlashesPath = urlPath.replace(/^\/+|\/+$/g, '');

  return trimmedSlashesPath
    .split('/')
    .map((p) => `${p.charAt(0).toUpperCase()}${p.slice(1)}`)
    .join(': ');
};

export const buildUrlParameters = (params) => {
  return Object.entries(params)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

export const removeUrlDoubleSlash = (url) => {
  const correctedUrl = url.replace(/([^:]\/)\/+/g, '$1');

  if (correctedUrl.startsWith('//') && !correctedUrl.startsWith('http')) {
    return correctedUrl.substring(1);
  }

  return correctedUrl;
};

export const getBaseDomain = (hostname) => {
  if (hostname.includes('localhost')) {
    return window.location.host;
  }

  const parts = hostname.split('.');

  if (parts.length > 2) {
    return parts.slice(-2).join('.');
  }

  return hostname;
};
