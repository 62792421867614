import { captureException } from '@sentry/browser';
import crypto from 'crypto';

export const useIntercom = ({ intercomAppID, intercomSecretKey }) => {
  if (!intercomAppID || !intercomSecretKey) {
    console.warn('Intercom configuration is missing required parameters.');
    return { boot: () => {}, update: () => {} };
  }

  const isBrowser = typeof window !== 'undefined';

  const boot = ({ email, userId }) => {
    try {
      if (!isBrowser) {
        console.warn('Intercom should not be initialized on the server.');
        return;
      }

      if (!window?.Intercom) {
        console.warn('Intercom is not loaded on the page.');
        return;
      }

      const intercomConfig = {
        api_base: 'https://api-iam.intercom.io',
        app_id: intercomAppID,
      };

      if (email && userId) {
        const hash = crypto.createHmac('sha256', intercomSecretKey).update(userId).digest('hex');

        Object.assign(intercomConfig, {
          user_id: userId,
          email,
          user_hash: hash,
        });
      }

      window.Intercom('boot', intercomConfig);
      update();
    } catch (error) {
      captureException(error);
      console.error('Error booting Intercom:', error);
    }
  };

  const update = () => {
    try {
      if (!isBrowser) {
        console.warn('Intercom update should not be called on the server.');
        return;
      }

      if (!window?.Intercom) {
        console.warn('Intercom is not loaded on the page.');
        return;
      }

      window.Intercom('update', {
        hide_default_launcher: false,
      });
    } catch (error) {
      captureException(error);
      console.error('Error updating Intercom:', error);
    }
  };

  return { boot, update };
};
