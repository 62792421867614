import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';

export default function ({ req, store }) {
  if (process.server) {
    const host = req.headers['x-original-host'] || req.headers.host;
    let brand = '';

    if (host.includes('fitover40.health')) {
      brand = PROJECT_BRANDS.fitover40;
    } else {
      brand = PROJECT_BRANDS.reverse;
    }

    if (!store.state.brand.brand) {
      store.commit('brand/SET_BRAND', brand);
    }
  }
}
