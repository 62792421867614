/**
 * Determines if moving from one step to another in a quiz represents a forward direction.
 *
 *
 * @param {Array<{id: string}>} quizList - An array of objects representing the steps of the quiz. Each object should have an `id` property.
 * @param {string} urlFrom - The `id` of the starting step.
 * @param {string} urlTo - The `id` of the target step.
 * @returns {boolean} `true` if the target step is after the starting step in the `quizList`, otherwise `false`.
 */
const isStepDirectionForward = (quizList, urlFrom, urlTo) => {
  const indexFrom = quizList.findIndex((item) => item.id === urlFrom);
  const indexTo = quizList.findIndex((item) => item.id === urlTo);

  if (indexTo === -1 || indexFrom === -1) {
    return false;
  }

  return indexTo > indexFrom;
};

export { isStepDirectionForward };
