import * as actions from './actions';
import * as getters from './getters';

import {
  FETCH_CANCELLATION_NAVIGATION,
  GET_SUBSCRIPTION_HISTORY,
  SET_CANCELLATION_NAVIGATION,
  SET_IS_MEMBERS_AREA,
  SET_REACTIVATE_SUBSCRIPTION_FETCHING,
  SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE,
  SET_SOURCE_APP,
  SET_SUBSCRIPTION_FETCHING,
  SET_SUBSCRIPTION_HISTORY_FETCHING,
  SET_UTM_OBJECT,
} from '../mutation-types';

const mutations = {
  [SET_CANCELLATION_NAVIGATION](state, payload = { status: 'active' }) {
    state.userSubscription = payload;
  },
  [FETCH_CANCELLATION_NAVIGATION](state, payload) {
    state.isFetchLoading = payload;
  },
  [GET_SUBSCRIPTION_HISTORY](state, payload) {
    state.subscriptionHistory = payload;
  },

  [SET_SUBSCRIPTION_FETCHING](state, payload) {
    state.isFetchingSubscription = payload;
  },
  [SET_SUBSCRIPTION_HISTORY_FETCHING](state, payload) {
    state.isFetchingSubHistory = payload;
  },
  [SET_REACTIVATE_SUBSCRIPTION_FETCHING](state, payload) {
    state.isFetchingReactivateSubscription = payload;
  },
  [SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE](state, payload) {
    state.showContentByTrafficSource = payload;
  },
  [SET_SOURCE_APP](state, payload) {
    state.sourceApp = payload;
  },
  [SET_UTM_OBJECT](state, payload) {
    state.utmObject = payload;
  },
  [SET_IS_MEMBERS_AREA](state, payload) {
    state.isMembersArea = payload;
  },
};

const state = () => ({
  userSubscription: {},
  isFetchLoading: true,
  subscriptionHistory: [],
  isFetchingSubscription: false,
  isFetchingSubHistory: false,
  isFetchingReactivateSubscription: false,
  showContentByTrafficSource: false,
  sourceApp: false,
  utmObject: {},
  isMembersArea: false,
});

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
