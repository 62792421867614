import * as actions from './actions';
import * as getters from './getters';

import {
  SET_UPSELL_CURRENT_COMPONENT_NAME,
  SET_UPSELL_CURRENT_INDEX,
  SET_UPSELL_LIST,
  SET_UPSELL_PHYSICAL_CURRENT_INDEX,
  SET_UPSELL_PHYSICAL_LIST,
} from '../mutation-types';

const mutations = {
  [SET_UPSELL_CURRENT_COMPONENT_NAME](state, payload) {
    state.upsellCurrentComponentName = payload;
  },
  [SET_UPSELL_LIST](state, payload) {
    state.upsellList = payload;
  },
  [SET_UPSELL_CURRENT_INDEX](state, payload) {
    state.upsellListCurrentIndex = payload;
  },
  [SET_UPSELL_PHYSICAL_LIST](state, payload) {
    state.upsellPhysicalList = payload;
  },
  [SET_UPSELL_PHYSICAL_CURRENT_INDEX](state, payload) {
    state.upsellPhysicalListCurrentIndex = payload;
  },
};

const state = () => ({
  upsellCurrentComponentName: '',
  upsellList: [],
  upsellListLength: 0,
  upsellListCurrentIndex: 0,

  upsellPhysicalList: [],
  upsellPhysicalListLength: 0,
  upsellPhysicalListCurrentIndex: 0,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
