import * as actions from './actions';
import * as getters from './getters';

import { SET_ACTIVE_SUBSCRIPTION_STATUS } from '../mutation-types';

const mutations = {
  [SET_ACTIVE_SUBSCRIPTION_STATUS](state, payload) {
    state.activeSubscription = payload;
  },
};

const state = () => ({
  activeSubscription: null,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
