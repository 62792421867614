const WHITELISTED_DOMAINS = {
  segment: 'https://*.segment.com',
  google: 'https://google.com https://*.google.com',
  cloudflare: 'https://*.cloudflareinsights.com  https://*.cloudflare.com',
  braintree: 'https://*.braintree-api.com https://*.braintreegateway.com',
  datadog: 'https://browser-intake-datadoghq.eu',
  gtm: 'https://www.googletagmanager.com',
  googleFonts: 'https://fonts.googleapis.com https://fonts.gstatic.com',
  kv: 'https://staging-kv-config-ab-testing.reversegroupio.workers.dev/ https://kv-config-ab-testing.reversegroupio.workers.dev/',
  rh: 'https://*.reverse.health https://reverse.health',
  fo40: 'https://*.fitover40.health https://fitover40.health',
  tiktok: 'https://*.tiktok.com',
  facebook: 'https://*.facebook.com https://*.facebook.net',
  twitter: 'https://*.twitter.com https://*.ads-twitter.com',
  sentry: 'https://*.sentry.io',
  bing: 'https://*.bing.com',
  pinterest: 'https://*.pinimg.com https://*.pinterest.com',
  adNetworks: 'https://www.rgds43jd.com https://www.dwin1.com https://*.hyr.so',
  cookies: 'https://*.cookielaw.org https://cookielaw.com',
};

const {
  segment,
  google,
  cloudflare,
  braintree,
  datadog,
  gtm,
  googleFonts,
  kv,
  rh,
  fo40,
  tiktok,
  facebook,
  twitter,
  sentry,
  bing,
  cookies,
  pinterest,
  adNetworks,
} = WHITELISTED_DOMAINS;

const analytics = `${segment} ${google} ${cloudflare} ${braintree} ${gtm} ${twitter} ${facebook} ${tiktok} ${datadog} ${sentry} ${bing} ${adNetworks} ${pinterest}`;

export const cspHeader = `
    default-src 'self' ${analytics} ${rh} ${fo40};
    script-src 'self' 'unsafe-eval' 'unsafe-inline' ${analytics} ${rh} ${fo40};
    style-src 'self' 'unsafe-inline' ${googleFonts};
    img-src 'self' blob: data: ${rh} ${fo40};
    font-src 'self' ${googleFonts};
    object-src 'none';
    base-uri 'self';
    form-action 'self';
    frame-ancestors 'none';
    connect-src 'self' ${analytics} ${kv} ${rh} ${fo40} ${cookies};
    worker-src 'self' blob: ${rh} ${fo40};
`
  .replace(/\s{2,}/g, ' ')
  .trim();

export default function (context) {
  const excludeRoutes = /^\/(_nuxt\/static|_nuxt\/image|api|favicon\.ico)/;

  if (process.server && !excludeRoutes.test(context.route.path)) {
    context.res.setHeader('Content-Security-Policy-Report-Only', cspHeader);
  }
}
