import { REGIONS } from '@/modules/region/domain/region';

import { createUpsell } from '../upsell';

export const oEX9H0 = createUpsell({
  id: 'oEX9H0',
  desc: 'Upsell sequence for Pilates US test with different price for fast food',
  regions: [REGIONS.us],
  seq: [
    'upsell-12-month',
    'upsell-yoga-healthy-back',
    {
      upsellPhysical: ['upsell-wc'],
    },
    'upsell-fast-food-pilates',
    'upsell-detox',
    'upsell-alcohol',
    'upsell-sweet-treats',
  ],
});
