import { validateUpsellPayload } from './validators/upsellPurchaseValidator';

export const purchaseUpsellHandler = (upsellRepository) => ({
  /**
   * Purchases an upsell by sending the required data to the API.
   * @param {Object} payload - The payload containing user and upsell information.
   * @param {string} payload.firstName - User's first name.
   * @param {string} payload.lastName - User's last name.
   * @param {string} payload.email - User's email address.
   * @param {Array} payload.orders - Array of order details.
   * @param {Object} payload.payment - Payment information.
   * @param {string} payload.payment.method - Payment method (e.g., sticky, paypal, etc.).
   * @param {string} payload.payment.type - Payment type (e.g., oneWeek, twelveMonths, etc.).
   * @param {string} payload.payment.previousOrderId - Previous order id
   * @param {string} payload.payment.orderId - Current order id
   * @param {string} payload.siteCountry - Country code (e.g., 'US').
   * @param {string} payload.uuid - Unique identifier for the user.
   * @returns {Promise<Object>} Response from the API.
   */
  execute: async (payload, { onSuccess, onFailure }) => {
    try {
      validateUpsellPayload(payload);
      const response = await upsellRepository.purchaseUpsell(payload);
      onSuccess(response);
    } catch (error) {
      const enhancedError = new Error(`Error in purchaseUpsellUseCase: ${error.message}`);
      enhancedError.originalError = error;
      onFailure(enhancedError);
    }
  },
});
