import { getAppHead } from '@/services/head.js';

export default function ({ req, app }) {
  let domain = process.server ? req.headers.host : window.location.hostname;
  const dynamicHead = getAppHead({ host: domain });

  app.head = {
    ...app.head,
    ...dynamicHead,
    meta: [...(app.head?.meta || []), ...(dynamicHead.meta || [])],
    link: [...(app.head?.link || []), ...(dynamicHead.link || [])],
  };
}
