export const QUIZ_PRODUCT_TYPES = Object.freeze({
  nutritionMenopause: 'nutrition-menopause',
  nutritionKeto: 'nutrition-keto',
  fitnessPilates: 'fitness-pilates',
  nutritionGeneric: 'nutrition-generic',
  fitnessYoga: 'fitness-yoga',
  fitnessYogaMenopause: 'fitness-yoga-menopause',
  fitnessSomaticYoga: 'fitness-somatic-yoga',
  fitnessSomaticExercises: 'fitness-somatic-exercises',
  fakeFitOver40: 'fake-fit-over-40',
  fitOver40: 'fit-over-40',
  fitnessWalking: 'fitness-walking',
});

export const DEFAULT_PRODUCT_TYPES = Object.freeze([
  {
    194: 'fitness-pilates',
    tourId: 194,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    193: 'fitness-pilates',
    tourId: 193,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    192: 'nutrition-menopause',
    tourId: 192,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    0: 'nutrition-menopause',
    tourId: 0,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    205: 'nutrition-keto',
    tourId: 205,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    203: 'fitness-pilates',
    tourId: 203,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    209: 'fitness-pilates',
    tourId: 209,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    191: 'nutrition-menopause',
    tourId: 191,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    190: 'nutrition-menopause',
    tourId: 190,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    210: 'fitness-yoga-menopause',
    tourId: 210,
    productType: 'fitness-yoga-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    196: 'nutrition-menopause',
    tourId: 196,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    204: 'nutrition-menopause',
    tourId: 204,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    198: 'fitness-pilates',
    tourId: 198,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    199: 'fitness-pilates',
    tourId: 199,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    200: 'fitness-pilates',
    tourId: 200,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    201: 'fitness-pilates',
    tourId: 201,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    202: 'fitness-pilates',
    tourId: 202,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    189: 'nutrition-menopause',
    tourId: 189,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    212: 'nutrition-keto',
    tourId: 212,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    219: 'fitness-somatic-yoga',
    tourId: 219,
    productType: 'fitness-somatic-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    206: 'fitness-pilates',
    tourId: 206,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    207: 'fitness-pilates',
    tourId: 207,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    208: 'fitness-pilates',
    tourId: 208,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    188: 'nutrition-keto',
    tourId: 188,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    217: 'nutrition-menopause',
    tourId: 217,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    227: 'fitness-pilates',
    tourId: 227,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    220: 'fitness-somatic-exercises',
    tourId: 220,
    productType: 'fitness-somatic-exercises',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    240: 'fitness-pilates',
    tourId: 240,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    218: 'nutrition-menopause',
    tourId: 218,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    239: 'fitness-pilates',
    tourId: 239,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    213: 'fitness-pilates',
    tourId: 213,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    222: 'fitness-pilates',
    tourId: 222,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    223: 'fitness-yoga',
    tourId: 223,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    224: 'fitness-yoga',
    tourId: 224,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    183: 'fitness-calisthenics',
    tourId: 183,
    productType: 'fitness-calisthenics',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    232: 'nutrition-menopause',
    tourId: 232,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    234: 'fitness-yoga',
    tourId: 234,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    164: 'nutrition-menopause',
    tourId: 164,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    165: 'nutrition-menopause',
    tourId: 165,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    166: 'nutrition-menopause',
    tourId: 166,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    167: 'nutrition-menopause',
    tourId: 167,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    169: 'nutrition-menopause',
    tourId: 169,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    168: 'nutrition-menopause',
    tourId: 168,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    171: 'nutrition-menopause',
    tourId: 171,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    170: 'nutrition-menopause',
    tourId: 170,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    172: 'nutrition-menopause',
    tourId: 172,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    142: 'nutrition-menopause',
    tourId: 142,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    150: 'nutrition-keto',
    tourId: 150,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    148: 'nutrition-menopause',
    tourId: 148,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    159: 'nutrition-menopause',
    tourId: 159,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    160: 'nutrition-menopause',
    tourId: 160,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    161: 'nutrition-keto',
    tourId: 161,
    productType: 'nutrition-keto',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    162: 'nutrition-keto',
    tourId: 162,
    productType: 'nutrition-keto',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    175: 'fitness-yoga',
    tourId: 175,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    179: 'fitness-pilates',
    tourId: 179,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    180: 'fitness-pilates',
    tourId: 180,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    185: 'nutrition-menopause',
    tourId: 185,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    186: 'nutrition-menopause',
    tourId: 186,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    187: 'nutrition-menopause',
    tourId: 187,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    152: 'fitness-pilates',
    tourId: 152,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    151: 'fitness-pilates',
    tourId: 151,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    154: 'fitness-pilates',
    tourId: 154,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    144: 'fitness-pilates',
    tourId: 144,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    130: 'nutrition-menopause',
    tourId: 130,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    135: 'nutrition-menopause',
    tourId: 135,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    124: 'nutrition-menopause',
    tourId: 124,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    125: 'nutrition-generic',
    tourId: 125,
    productType: 'nutrition-generic',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    177: 'fitness-pilates',
    tourId: 177,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    173: 'nutrition-menopause',
    tourId: 173,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    157: 'nutrition-menopause',
    tourId: 157,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    156: 'fitness-pilates',
    tourId: 156,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    155: 'fitness-pilates',
    tourId: 155,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    149: 'fitness-pilates',
    tourId: 149,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    147: 'fitness-pilates',
    tourId: 147,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    131: 'fitness-pilates',
    tourId: 131,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    133: 'fitness-pilates',
    tourId: 133,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    181: 'nutrition-menopause',
    tourId: 181,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    182: 'nutrition-menopause',
    tourId: 182,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    99: 'nutrition-menopause',
    tourId: 99,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    163: 'nutrition-menopause',
    tourId: 163,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    178: 'fitness-pilates',
    tourId: 178,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    163: 'nutrition-menopause',
    tourId: 163,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    233: 'fitness-pilates',
    tourId: 233,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    145: 'fitness-pilates',
    tourId: 145,
    productType: 'fitness-pilates',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    140: 'nutrition-menopause',
    tourId: 140,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    228: 'fitness-pilates',
    tourId: 228,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    231: 'nutrition-menopause',
    tourId: 231,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    229: 'fitness-pilates',
    tourId: 229,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    230: 'fitness-pilates',
    tourId: 230,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    174: 'fitness-yoga',
    tourId: 174,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    242: 'fitness-pilates',
    tourId: 242,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'FitOver40',
    checkoutVariation: null,
  },
  {
    246: 'nutrition-menopause',
    tourId: 246,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    247: 'nutrition-menopause',
    tourId: 247,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    176: 'fitness-yoga',
    tourId: 176,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    139: 'fitness-pilates',
    tourId: 139,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'pilates-now',
  },
  {
    216: 'nutrition-menopause',
    tourId: 216,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    215: 'fitness-yoga',
    tourId: 215,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    214: 'fitness-pilates',
    tourId: 214,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    211: 'fitness-pilates',
    tourId: 211,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    184: 'fitness-yoga',
    tourId: 184,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    248: 'nutrition-menopause',
    tourId: 248,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    249: 'nutrition-menopause',
    tourId: 249,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    250: 'nutrition-menopause',
    tourId: 250,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    221: 'fitness-pilates',
    tourId: 221,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    237: 'fitness-pilates',
    tourId: 237,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    236: 'nutrition-menopause',
    tourId: 236,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    238: 'fitness-yoga',
    tourId: 238,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: 'compliance',
  },
  {
    255: 'fitness-yoga',
    tourId: 255,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    256: 'fitness-pilates',
    tourId: 256,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: '',
  },
  {
    257: 'nutrition-menopause',
    tourId: 257,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    259: 'nutrition-menopause',
    tourId: 259,
    productType: 'nutrition-menopause',
    pricingModel: 'Monthly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    260: 'nutrition-menopause',
    tourId: 260,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    261: 'fitness-pilates',
    tourId: 261,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    262: 'fitness-pilates',
    tourId: 262,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    263: 'fitness-pilates',
    tourId: 263,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    264: 'fitness-yoga',
    tourId: 264,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    265: 'fitness-yoga',
    tourId: 265,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    266: 'nutrition-menopause',
    tourId: 266,
    productType: 'nutrition-menopause',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    258: 'fitness-walking',
    tourId: 258,
    productType: 'fitness-walking',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    267: 'fitness-yoga',
    tourId: 267,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    272: 'fitness-yoga',
    tourId: 272,
    productType: 'fitness-yoga',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
  {
    274: 'fitness-pilates',
    tourId: 274,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    brand: 'ReverseHealth',
    checkoutVariation: null,
  },
]);
