// import dummyOfferTest from './dummy-offer-test';
// import dummyTourTest from './dummy-tour-test';
import pilates28DayChallengeCheckoutMoneyBackGuarantee from './pilates-28-day-challenge-checkout-money-back-guarantee';
import pilates28DayChallengeCheckoutRemovePaypalOptionInBr from './pilates-28-day-challenge-checkout-remove-paypal-option-in-br';
import pilates28DayChallengeUpdateBmiStep from './pilates-28-day-challenge-update-bmi-step';
import pilatesChallengeAndNutritionYogaPriceIncreaseAu from './pilates-challenge-and-nutrition-yoga-price-increase-au';
import pilates28DayChallengeMultiplePlanSelector from './pilates-challenge-multiple-plan-selector';
import quizUpdateGoalStepPilates from './quiz-update-goal-step-pilates';
import yoga28DayChallengeRevamp from './yoga-28-day-challenge-revamp';
import yogaFullBodyBeforeAfterImages from './yoga-full-body-before-after-images';

const tests = [
  // Dummy experiments
  // dummyOfferTest,
  // dummyTourTest,

  pilatesChallengeAndNutritionYogaPriceIncreaseAu,
  pilates28DayChallengeCheckoutRemovePaypalOptionInBr,
  pilates28DayChallengeCheckoutMoneyBackGuarantee,
  yogaFullBodyBeforeAfterImages,
  pilates28DayChallengeMultiplePlanSelector,
  quizUpdateGoalStepPilates,
  yoga28DayChallengeRevamp,
  pilates28DayChallengeUpdateBmiStep,
];

export default tests;
