import { Helpers } from '@/services/helpers';
import URLS from '@/services/urls';

import {
  SET_PAYMENT_3D_SECURE_URL,
  SET_PAYMENT_ADDRESS,
  SET_PAYMENT_AFFILIATES,
  SET_PAYMENT_COMPONENT_NAME,
  SET_PAYMENT_CREATE_USER_PROMISE,
  SET_PAYMENT_CUSTOMER_ID,
  SET_PAYMENT_EMAIL,
  SET_PAYMENT_FIRST_NAME,
  SET_PAYMENT_LAST_NAME,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_OFFER,
  SET_PAYMENT_ORDER_ID,
  SET_PAYMENT_ORDER_SUMMARY_PAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID,
  SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST,
  SET_PAYMENT_SELECTED,
  SET_PAYMENT_TOKEN,
  SET_PAYMENT_TRANSACTION_ID,
  SET_PAYMENT_VARIANT,
} from '../mutation-types';

/**
 *  Fetch payment token after sending card information
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Card information, cc, cvc, name
 * @return {Promise} Contain the status and token, expire
 */
export async function fetchPaymentTokenAction({ commit }, payload) {
  const { data } = await this.$payment.$post(URLS.PAYMENT_TOKEN, payload);
  commit(SET_PAYMENT_TOKEN, data.token);
  return Helpers.resolvePromise(data);
}

/**
 *  Set the selected payment options that the user select and send out an object
 *  that contain the payment type and price value
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - Selected payment object
 * @return {Object} Selected payment object
 */
export function setSelectedPaymentAction({ commit }, payload) {
  commit(SET_PAYMENT_SELECTED, payload);
}

/**
 *  Set the payment variant
 * @param {function} commit - The vuex commit function
 * @param {number} payload - payment variant
 * @return {number} payment variant
 */
export function setPaymentVariant({ commit }, payload) {
  commit(SET_PAYMENT_VARIANT, payload);
}

/**
 * Sync waiting for the user creation promises
 */
export async function waitUserCreation({ state, commit }) {
  if (state.createUserPromise) {
    await state.createUserPromise;
    commit(SET_PAYMENT_CREATE_USER_PROMISE, null);
  }
}

/**
 * Send magic link via SMS. Wait for the checkoutUserCreate action to complete first.
 */
export async function sendMagicLinkSmsAction({ state, commit }, { email, phoneNumber }) {
  await waitUserCreation({ state, commit });
  await this.$api.$post(URLS.SEND_WELCOME_SMS, {
    email,
    phoneNumber,
  });
}

/**
 *  Set component name value
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Component name value
 * @return {String} Component name value
 */
export function setComponentName({ commit }, payload) {
  commit(SET_PAYMENT_COMPONENT_NAME, payload);
}

export async function cancelSubscription({ rootState }, args) {
  const resp = await this.$api.$post(URLS.CANCEL_SUBSCRIPTION, {
    ...args,
    source: rootState.common.sourceApp ? 'app' : 'web',
  });
  return resp;
}

export async function updateSubscriptionFromCancel(_, args) {
  const resp = await this.$api.$post(URLS.UPDATE_SUBSCRIPTION_FROM_CANCEL, args);
  return resp;
}

export async function pauseSubscriptionFromCancel(_, args) {
  const resp = await this.$api.$post(URLS.PAUSE_SUBSCRIPTION_FROM_CANCEL, args);
  return resp;
}

export async function updateUserPaymentData(_, args) {
  const resp = await this.$api.$post(URLS.UPDATE_USER_PAYMENT_DATA, args);
  return resp;
}

/**
 *  Set 3d secure iframe url
 * @param {function} commit - The vuex commit function
 * @param {String} orderId - orderId
 * @return {String} 3d secure iframe url
 */
export function set3DSecureURL({ commit }, { orderId }) {
  commit(SET_PAYMENT_3D_SECURE_URL, URLS.THREE_D_SECURE_LINK(orderId));
}

/**
 *  Set paid order summary
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setPaidOrderSummary({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_PAID, payload);
}

/**
 *  Set unpaid order summary
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setUnpaidOrderSummary({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_UNPAID, payload);
}

/**
 *  Set unpaid order summary list
 * @param {function} commit - The vuex commit function
 * @param {string} payload - Order type
 * @return {String[]} - Order summary types
 */
export function setUnpaidOrderSummaryList({ commit }, payload) {
  commit(SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST, payload);
}

/**
 *  Set affiliates
 * @param {function} commit - The vuex commit function
 * @param {boolean} payload - Affiliates
 */
export function setAffiliates({ commit }, payload) {
  commit(SET_PAYMENT_AFFILIATES, payload);
}

/**
 * Sets the required info when you arrive to the upsells page.
 *
 * @param {Object} context - The Vuex action context.
 * @param {Function} context.commit - The Vuex commit function.
 * @param {Object} payload - The payload containing payment information.
 */
export async function setPaymentUpsellsInfo({ commit }, payload) {
  const { email, method, orderId, selectedPricingPlan, customerId, order } = payload;

  const { shipping_country: country, transaction_id: transactionId } = order;
  const firstName = order.first_name || '';
  const lastName = order.last_name || '';
  const fullName = `${firstName} ${lastName}`;

  commit(SET_PAYMENT_ADDRESS, { country, fullName });
  commit(SET_PAYMENT_FIRST_NAME, lastName);
  commit(SET_PAYMENT_LAST_NAME, firstName);
  commit(SET_PAYMENT_EMAIL, email);
  commit(SET_PAYMENT_METHOD, method);
  commit(SET_PAYMENT_OFFER, selectedPricingPlan?.type);
  commit(SET_PAYMENT_ORDER_ID, orderId);
  commit(SET_PAYMENT_CUSTOMER_ID, customerId);
  commit(SET_PAYMENT_TRANSACTION_ID, transactionId);
}
