const MANAGE_YOUR_SUBSCRIPTION_LINK = {
  label: 'landingPage.footer.navigation.manage-your-subscription.label',
  url: '/manage-your-subscription',
  isExternal: false,
};

const LOGIN_LINK = {
  label: 'landingPage.footer.navigation.login.label',
  url: '/login',
  isExternal: false,
};

const CONTACT_LINK = {
  label: 'landingPage.footer.navigation.contact.label',
  url: '/contact',
  isExternal: true,
};

export const getFooterLinksByBrand = (isRh = true, getHelpCenterUrl) => {
  const RH_FOOTER_LINKS = [
    {
      label: 'landingPage.footer.navigation.help-center.label',
      url: getHelpCenterUrl(),
      isExternal: true,
    },
  ];

  const F040_FOOTER_LINKS = [
    {
      label: 'landingPage.footer.navigation.help-center.label',
      url: getHelpCenterUrl(),
      isExternal: true,
    },
  ];

  const extraLink = isRh ? RH_FOOTER_LINKS : F040_FOOTER_LINKS;

  return [MANAGE_YOUR_SUBSCRIPTION_LINK, LOGIN_LINK, ...extraLink, CONTACT_LINK];
};

export const getPrivacyAndTermsByBrand = ({ isRh = true, tosCb, ppUrlCb }) => {
  const RH_PRIVACY_AND_TERMS = [
    {
      label: 'landingPage.footer.navigation.terms.label',
      url: tosCb,
    },
    {
      label: 'landingPage.footer.navigation.privacy.label',
      url: ppUrlCb,
    },
  ];

  const F040_PRIVACY_AND_TERMS = [
    {
      label: 'landingPage.footer.navigation.terms.label',
      url: '/terms-of-service',
    },
    {
      label: 'landingPage.footer.navigation.privacy.label',
      url: '/privacy-policy',
    },
  ];

  return isRh ? RH_PRIVACY_AND_TERMS : F040_PRIVACY_AND_TERMS;
};
