export const REGIONS = Object.freeze({
  us: 'US',
  br: 'BR',
  ca: 'CA',
  uk: 'UK',
  eu: 'EU',
  au: 'AU',
  sa: 'SA',
  nz: 'NZ',
  latam: 'LATAM',
  join: 'JOIN',
});
