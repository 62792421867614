import { AUTH_LOGIN } from '@/components/auth/helpers/auth-component-names';

import * as actions from './actions';
import * as getters from './getters';

import {
  SET_AUTH_COMPONENT_NAME,
  SET_AUTH_LOGIN_EMAIL,
  SET_AUTH_LOGIN_UUID,
  SET_AUTH_MAGIC_LINK,
  SET_AUTH_MAGIC_LINK_FETCHING,
  SET_AUTH_RESET_PASSWORD_PASSWORD,
  SET_AUTH_RESET_PASSWORD_TOKEN,
  SET_AUTH_SUGGESTIC_TOKEN,
  SET_IS_TAPPTITUDE_USER,
  SET_JWT_DATA,
} from '../mutation-types';

const mutations = {
  [SET_AUTH_LOGIN_EMAIL](state, payload) {
    state.login.email = payload;
  },
  [SET_AUTH_RESET_PASSWORD_TOKEN](state, payload) {
    state.resetPassword.token = payload;
  },
  [SET_AUTH_RESET_PASSWORD_PASSWORD](state, payload) {
    state.resetPassword.password = payload;
  },
  [SET_AUTH_COMPONENT_NAME](state, payload) {
    state.componentName = payload;
  },
  [SET_AUTH_SUGGESTIC_TOKEN](state, payload) {
    state.suggesticToken = payload;
  },
  [SET_AUTH_MAGIC_LINK](state, payload) {
    state.magicLink = payload;
  },
  [SET_AUTH_MAGIC_LINK_FETCHING](state, payload) {
    state.isFetchingMagicLink = payload;
  },
  [SET_JWT_DATA](state, payload) {
    state.jwt = payload;
  },
  [SET_IS_TAPPTITUDE_USER](state, payload) {
    state.isTapptitudeUser = payload;
  },
  [SET_AUTH_LOGIN_UUID](state, payload) {
    state.login.uuid = payload;
  },
};

const state = () => ({
  login: {
    email: null,
  },
  resetPassword: {
    token: null,
    password: null,
  },
  jwt: {
    userId: null,
    suggesticId: null,
  },
  componentName: AUTH_LOGIN,
  suggesticToken: null,
  magicLink: null,
  isFetchingMagicLink: false,
});

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
};
