import { REGIONS } from '@/modules/region/domain/region';

import { createUpsell } from '../upsell';

export const Ajk2S0 = createUpsell({
  id: 'Ajk2S0',
  desc: 'Upsell sequence fitness - UK, AU, JOIN',
  regions: [REGIONS.uk, REGIONS.au, REGIONS.join],
  seq: [
    'upsell-12-month',
    'upsell-yoga-healthy-back',
    'upsell-fast-food',
    'upsell-detox',
    'upsell-alcohol',
    'upsell-sweet-treats',
  ],
});
