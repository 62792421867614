import { COOKIES } from '@/services/constants';

import defaultUrls from './default.urls';
import esUrls from './es.urls';

const getUrlsMap = {
  es: { ...defaultUrls, ...esUrls },
  default: defaultUrls,
};

export default function ({ $cookies }, inject) {
  const getUrl = (page, queryParams = {}) => {
    const lang = $cookies.get(COOKIES.lang) || null;
    const urlsMap = getUrlsMap[lang] || getUrlsMap.default;
    const urlBuilder = urlsMap[page] || getUrlsMap.default[page];

    if (!urlBuilder) {
      throw new Error(`UrlBuilder error: Page ${page} does not exist`);
    }

    const path = urlBuilder({ lang, queryParams });
    const query = Object.entries(queryParams).map(
      ([queryParamName, queryParamValue]) => `${queryParamName}=${queryParamValue}`,
    );

    return query && query.length ? `${path}?${query.join('&')}` : path;
  };

  inject('getUrl', getUrl);
}
