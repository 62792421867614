import { Helpers } from '@/services/helpers';
import URLS from '@/services/urls';

import { SET_HAS_LEAD_STATUS, SET_LEAD_EXPERIMENTS, SET_LEAD_UUID } from '../mutation-types';

/**
 *  Check if giving email have already lead
 * @param {function} commit - The vuex commit function
 * @param {String} payload - User email address
 * @return {Promise} Contain the lead status
 */
export async function checkIfUserHaveLead({ commit }, payload) {
  try {
    const enocoded = encodeURIComponent(payload);
    const { lead } = await this.$api.$get(URLS.CHECK_LEAD_BY_EMAIL(enocoded), {
      transformRequest: (data, headers) => {
        delete headers.common.Authorization;
        delete headers.Authorization;
        return data;
      },
    });
    commit(SET_HAS_LEAD_STATUS, lead);
    return Helpers.resolvePromise(lead);
  } catch {
    commit(SET_HAS_LEAD_STATUS, null);
  }
}
export async function checkIfUserHaveLeadDetails({ commit }, payload) {
  try {
    const enocoded = encodeURIComponent(payload);
    const data = await this.$api.$get(URLS.CHECK_LEAD_BY_EMAIL(enocoded), {
      transformRequest: (data, headers) => {
        delete headers.common.Authorization;
        delete headers.Authorization;
        return data;
      },
    });
    commit(SET_HAS_LEAD_STATUS, data.lead);
    return Helpers.resolvePromise(data);
  } catch {
    commit(SET_HAS_LEAD_STATUS, null);
  }
}

export async function setLeadUUID({ commit }, payload) {
  commit(SET_LEAD_UUID, payload);
}

export async function setLeadExperiments({ commit }, experiments) {
  commit(SET_LEAD_EXPERIMENTS, experiments);
}
