import { datadogRum } from '@datadog/browser-rum';

export default ({ app }) => {
  app.router.afterEach((to) => {
    if (datadogRum && typeof datadogRum.startView === 'function') {
      if (to.path === '/tour' || to.path.startsWith('/tour/')) {
        datadogRum.startView(`${to.path}`);
      }
    }
  });
};
