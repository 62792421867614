/**
 * Get login user email
 * @param {object} state - The vuex state object
 * @return {String} Login user email
 */
export const getLoginUserEmail = (state) => state.login.email;

/**
 * Get login user email
 * @param {object} state - The vuex state object
 * @return {String} Login uuid
 */
export const getLoginUuid = (state) => state.login.uuid;

/**
 * Get auth component name
 * @param {object} state - The vuex state object
 * @return {String} Auth component name
 */
export const getAuthComponentName = (state) => state.componentName;

/**
 * Get magic link
 * @param {object} state - The vuex state object
 * @return {String} Magic link value
 */
export const getMagicLink = (state) => state.magicLink;

export const getJwtData = (state) => state.jwt;
