
import { mapActions, mapGetters, mapState } from 'vuex';

import { AUTH_LOGIN } from '@/components/auth/helpers/auth-component-names';
import SvgContainer from '@/components/core/svg-container/svg-container';

export default {
  name: 'App',
  components: {
    SvgContainer,
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$exp.$classes.join(' '),
      },
      htmlAttrs: {
        lang: this.$i18n.locale.split('-')[0] || 'en',
      },
      script: [
        {
          hid: 'intercom-settings',
          innerHTML: `
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "${this.$config.intercomAppID}"
            };
          `,
        },
        {
          hid: 'intercom-widget',
          innerHTML: `
            (function(){
              var w=window;
              var ic=w.Intercom;
              if(typeof ic==="function"){
                ic('reattach_activator');
                ic('update',w.intercomSettings);
              }else{
                var d=document;
                var i=function(){i.c(arguments);};
                i.q=[];
                i.c=function(args){i.q.push(args);};
                w.Intercom=i;
                var l=function(){
                  var s=d.createElement('script');
                  s.type='text/javascript';
                  s.async=true;
                  s.src='https://widget.intercom.io/widget/${this.$config.intercomAppID}';
                  var x=d.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s,x);
                };
                if(document.readyState==='complete'){
                  l();
                }else if(w.attachEvent){
                  w.attachEvent('onload',l);
                }else{
                  w.addEventListener('load',l,false);
                }
              }
            })();
          `,
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    };
  },
  computed: {
    ...mapGetters('auth', ['getJwtData']),
    ...mapState({
      userSubscription: (state) => state.common.userSubscription,
    }),
  },
  watch: {
    $route() {
      if (window) window.scrollTo({ top: 0 });
      this.setAuthComponentName(AUTH_LOGIN);
    },
    userSubscription(val) {
      this.setupIntercom();
    },
  },
  mounted() {
    if (window && window.balanceText && typeof window.balanceText === 'function') {
      window.balanceText('.balance-text');
    }

    // This method will validate all query in the url and store them in vuex
    this.queryHandler();
    this.showIntercomWidget();
  },
  beforeDestroy() {
    if (window.Intercom) {
      window.Intercom('hide');
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthComponentName']),
  },
};
