import * as getters from './getters';

const mutations = {
  SET_BRAND(state, payload) {
    state.brand = payload;
  },
};

const state = () => ({
  brand: '',
});

export default {
  namespaced: true,
  mutations,
  getters,
  state,
};
