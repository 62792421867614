import * as actions from './actions';

import { SET_CHECKOUT_ID } from '../mutation-types';

const mutations = {
  [SET_CHECKOUT_ID](state, checkoutId) {
    state.checkoutId = checkoutId;
  },
};

const state = () => ({
  checkoutId: null,
});

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
