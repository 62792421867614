import URLS from '@/services/urls';

import { SET_ACTIVE_SUBSCRIPTION_STATUS } from '../mutation-types';

/**
 *  Check if giving email have already active subscription with RH
 * @param {function} commit - The vuex commit function
 * @param {String} payload - User email address
 * @return {Promise} Contain the subscription status
 */

// Function from https://axios-http.com/docs/cancellation
function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();

  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

export async function checkIfUserHaveActiveSubscription({ commit }, email) {
  try {
    const data = await this.$api.$post(
      URLS.CHECK_USER_SUBSCRIPTION,
      { email },
      {
        // This will take care of connection timeout
        signal: newAbortSignal(5000),
        // This will take care of response timeout
        timeout: 5000,
      },
    );
    commit(SET_ACTIVE_SUBSCRIPTION_STATUS, data?.subscription);
    return data?.subscription;
  } catch {
    commit(SET_ACTIVE_SUBSCRIPTION_STATUS, null);
  }
}
