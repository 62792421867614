import { EXPERIMENT_NAMES } from '@/modules/experiment/domain/experiment.constants';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  name: EXPERIMENT_NAMES.QUIZ_UPDATE_GOAL_STEP_PILATES,
  includedRoutes: ['/tour/pilates-28-day-challenge', '/tour/pilates-28-day-challenge/'],
  allocateOnClient: true,
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP}`,
    },
  ],
};
