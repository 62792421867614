import Vue from 'vue';

const CORE_VALUES = { weight: 2.20462, height: 2.54 };

const KG_TO_LBS = (value, decimals = 0) => {
  if (!value) return 0;

  return (value * CORE_VALUES.weight).toFixed(decimals);
};

const LBS_TO_KG = (value, decimals = 0) => {
  if (!value) return 0;

  return (value / CORE_VALUES.weight).toFixed(decimals);
};

const INCHES_TO_CM = (value) => {
  if (!value) return 0;

  return (value * CORE_VALUES.height).toFixed(0);
};

const INCHES_TO_CM_FLOAT = (value) => {
  if (!value) return 0;

  return value * CORE_VALUES.height;
};

const CM_TO_INCHES = (value, decimals = 0) => {
  if (!value) return 0;

  return (value / CORE_VALUES.height).toFixed(decimals);
};

const CM_TO_FEET = (value, decimals = 0) => {
  if (!value) return { feet: 0, inches: 0 };

  const totalInches = value / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return { feet, inches: inches.toFixed(decimals) };
};

const FEET_INCH_TO_CM = (value, decimals = 0) => {
  if (!value) return 0;

  const { feet, inches } = value;
  const totalInches = feet * 12 + inches;
  return (totalInches * 2.54).toFixed(decimals);
};

const ST_TO_KG = (value) => {
  if (!value) return 0;

  return Math.floor(value * 6.35029);
};

const KG_TO_ST = (value) => {
  if (!value) return 0;

  return Math.floor(value / 6.35029);
};

const KG_TO_ST_AND_LBS = (value, decimals = 0) => {
  if (!value) return { stones: 0, pounds: 0 };

  const KG_TO_LB = 2.20462;
  const POUNDS_IN_STONE = 14;

  const totalPounds = value * KG_TO_LB;

  const totalStones = Math.floor(totalPounds / POUNDS_IN_STONE);

  const remainingPounds = totalPounds % POUNDS_IN_STONE;

  return { stones: totalStones, pounds: remainingPounds.toFixed(decimals) };
};

const ST_AND_LBS_TO_KG = (stones, pounds, decimals = 0) => {
  if (!stones && !pounds) return 0;

  const STONE_TO_LB = 14;
  const LB_TO_KG = 0.453592;

  const totalPounds = stones * STONE_TO_LB + pounds;

  const totalKg = totalPounds * LB_TO_KG;

  return totalKg.toFixed(decimals);
};

Vue.filter('kgToLbs', KG_TO_LBS);
Vue.filter('lbsToKg', LBS_TO_KG);
Vue.filter('inchesToCm', INCHES_TO_CM);
Vue.filter('inchesToCmFloat', INCHES_TO_CM_FLOAT);
Vue.filter('cmToInches', CM_TO_INCHES);
Vue.filter('cmToFeet', CM_TO_FEET);
Vue.filter('feetInchesToCm', FEET_INCH_TO_CM);
Vue.filter('stToKg', ST_TO_KG);
Vue.filter('kgToSt', KG_TO_ST);
Vue.filter('kgToStAndLbs', KG_TO_ST_AND_LBS);
Vue.filter('stAndLbsToKg', ST_AND_LBS_TO_KG);
