import { PROJECT_BRANDS } from '@/modules/brand/domain/brand.js';
import { QUIZ_PRODUCT_TYPES } from '@/modules/product/domain/product';
import { REGIONS } from '@/modules/region/domain/region';
import { PRICING_MODELS } from '@/services/constants';

import * as upsells from '../domain/sequences';

const createShouldOfferUpsell = (upsellRegions) => (region) => upsellRegions.includes(region);

const __shouldOffer12MonthUpsell = createShouldOfferUpsell(upsells.jdz8pH.regions);
const __shouldOffer12MonthUpsellVariant = createShouldOfferUpsell(upsells.yeLWMM.regions);
const __shouldOfferCollagenUpsell = createShouldOfferUpsell(upsells.YrhVjZ.regions);

const UPSELL_RULES = [
  {
    condition: ({ region, brand }) => region === REGIONS.us && brand === PROJECT_BRANDS.fitover40,
    sequence: upsells.i3L3qh.sequence,
  },
  {
    condition: ({ productType, pricingModel, region }) =>
      region === REGIONS.us &&
      productType === QUIZ_PRODUCT_TYPES.fitnessPilates &&
      pricingModel === PRICING_MODELS.weekly,
    sequence: upsells.oEX9H0.sequence,
  },
  {
    condition: ({ productType, region }) =>
      productType === QUIZ_PRODUCT_TYPES.fitnessPilates && [REGIONS.uk, REGIONS.au, REGIONS.join].includes(region),
    sequence: upsells.Ajk2S0.sequence,
  },
  {
    condition: ({ region }) => __shouldOfferCollagenUpsell(region),
    sequence: upsells.YrhVjZ.sequence,
  },
  {
    condition: ({ region }) => __shouldOffer12MonthUpsellVariant(region),
    sequence: upsells.yeLWMM.sequence,
  },
  {
    condition: ({ region }) => __shouldOffer12MonthUpsell(region),
    sequence: upsells.jdz8pH.sequence,
  },
];

export const getUpsellSequence = ({ productType, pricingModel, region, brand = PROJECT_BRANDS.reverse }) => {
  if (!region && brand === PROJECT_BRANDS.reverse) {
    return upsells.K7m6Xc.sequence;
  }

  const rule = UPSELL_RULES.find(({ condition }) => condition({ productType, pricingModel, region, brand }));

  // Return the matched sequence or default one per brand
  if (brand === PROJECT_BRANDS.fitover40) {
    return rule ? rule.sequence : upsells.i3L3qh.sequence;
  }

  return rule ? rule.sequence : upsells.K7m6Xc.sequence;
};
