import { REGIONS } from '@/modules/region/domain/region';

const GENERIC_REGION = REGIONS.us;

/**
 *
 * @param {string} hostname
 */
export const useDomain = (hostname) => {
  const normalizedHostname = hostname?.toLowerCase() || '';

  const _hasRegion = () => {
    const [mightBeRegion] = normalizedHostname.split('.');

    return (
      !mightBeRegion.includes('reverse') &&
      !mightBeRegion.includes('fitover40') &&
      !mightBeRegion.includes('staging') &&
      !mightBeRegion.includes('local') &&
      !mightBeRegion.includes('tour')
    );
  };

  /**
   *
   * @returns string
   */
  const getCountryCode = () => {
    if (normalizedHostname.includes('herokuapp.com') || !normalizedHostname.length) {
      return GENERIC_REGION;
    }

    const hostnameHasRegion = _hasRegion();

    if (hostnameHasRegion) {
      const [region] = normalizedHostname.split('.');
      return region.toUpperCase();
    }

    return GENERIC_REGION;
  };

  return { getCountryCode };
};
