import axios from 'axios';

import { useDomain } from '@/composables/useDomain';
import URLS from '@/services/urls';

import { SET_CURRENT_DOMAIN_PREFIX, SET_DETECTED_COUNTRY, SET_PAYMENT_DETECTED_COUNTRY } from '../mutation-types';

/**
 * Fetch detected country from cloudflare quic service
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Manuel value
 * @return {undefined}
 */
export async function fetchDetectedCountryAction({ commit }, payload = null) {
  try {
    let selectedCountry = payload;

    if (!selectedCountry) {
      const { data } = await axios.get(URLS.CLOUDFLARE_QUIC_ALTERNATIVE);
      const [, countryCodePart] = data.split('loc=');
      const [country] = countryCodePart.split('\n');
      console.log('Detected Country by Cloudflare', country);
      selectedCountry = country;
    }

    commit(SET_DETECTED_COUNTRY, selectedCountry);
    commit(`payment/${SET_PAYMENT_DETECTED_COUNTRY}`, selectedCountry, { root: true });
  } catch (error) {
    this.$sentry.captureException(error);
    commit(SET_DETECTED_COUNTRY, 'US');
    commit(`payment/${SET_PAYMENT_DETECTED_COUNTRY}`, 'US', { root: true });
  }
}

/**
 * Set current domain prefix from url
 * @param {function} commit - The vuex commit function
 * @return {String} Should be UK || EU || US
 */
export function setCurrentDomainPrefix({ commit }) {
  const domain = useDomain(window.location.hostname);

  const countryCode = domain.getCountryCode();
  commit(SET_CURRENT_DOMAIN_PREFIX, countryCode);
}
